import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

//importing context consumer here
import { UserContext } from "../../contexts/User";
import { FoodContext } from "../../contexts/Food";
import { RestaurantContext } from "../../contexts/Restaurant";
import { SettingsContext } from "../../contexts/Settings";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../src/BaseUrl";

//functions
import {
    _t,
    modalLoading,
    restaurantMenuLink,
    getSystemSettings,
    getCookie,
    deleteCookie,
    currencySymbolLeft,
    formatPrice,
    currencySymbolRight,
} from "../../functions/Functions";

//3rd party packages
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//jQuery initialization
import $ from "jquery";

// template file
import Testimonial from "./Testimonial";
import Gallery from "./Gallery";
import Blog from "./Blog";
import DeliverySection from "./DeliverySection";
import Countdown from "./Countdown";
import Banner from "./Banner";
import About from "./About";


const BlogSingle = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    //getting context values here
    let {
        navLanguageList,
        navCurrencyList,
        generalSettings,
        showManageStock,
        paypal_client_id
    } =
        useContext(SettingsContext);
    //auth user
    const { authUserInfo } = useContext(UserContext);
    //restaurant
    let { branchForSearch } = useContext(RestaurantContext);
    //food
    let {
        getFoodWeb,
        foodListWeb,
        foodGroupWeb,
        propertyGroupWeb,
        workPeriodWeb,
        foodStockWeb,
        setFoodStockWeb,
    } = useContext(FoodContext);

    //use state
    const [loading, setLoading] = useState(true);
    const [showCart, setShowCart] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);
    const [showVariation, setShowVariation] = useState(false);

    // stripe
    const [showStripe, setshowStripe] = useState('');

    const [deliverymenShow, setdeliverymenShow] = useState(false);
    const [checkLoginUser, setCheckLoginUser] = useState(0);
    //
    const [defaultLang, setDefaultLang] = useState(null);
    const [defaultCurrency, setDefaultCurrency] = useState(null);

    // branch delivery charge
    const [branchDeliveryCharge, setBranchDeliveryCharge] = useState(0);

    //food and group
    const [foodItems, setFoodItems] = useState({
        list: null,
        group: null,
        selectedItem: null,
        variations: null,
        properties: null,
    });

    //new order
    const [newOrder, setNewOrder] = useState({
        variation: null,
        quantity: 1,
        properties: null,
    });

    //order details
    const [orderDetails, setOrderDetails] = useState({
        items: [],
        branch: null,
        workPeriod: null,
        workPeriodStatus: false,
        address: null,//null
        name: null,
        phn_no: null,
        note: null,
        payment_type: "COD",
        uploading: false,
    });

    // check auth user 
    const checkLoginfunc = () => {
        getCookie() !== undefined ? setCheckLoginUser(0) : setCheckLoginUser(1);
    }

    // show stripe 
    const showstripebtn = (e) => {
        setLoading(true);
        const url = BASE_URL + `/settings/show-stripe-btn`;
        return axios
            .get(url)
            .then((res) => {
                setshowStripe(res.data);
                setLoading(false);
            })
    }

    //useeffect == componentDidMount()
    useEffect(() => {
        handleJquery();
        getFoodWeb();
        deliveryMenu();
        handleOnLoadDefaultLang();
        handleOnLoadDefaultCurrency();
        checkLoginfunc();
        showstripebtn();
        setOrderDetails({
            ...orderDetails,
            address: authUserInfo.details && authUserInfo.details.address,
            name: authUserInfo.details && authUserInfo.details.name,
            phn_no: authUserInfo.details && authUserInfo.details.phn_no,
        });
        if (foodGroupWeb && foodListWeb) {
            let temp = foodListWeb.filter((foodItem, foodItemIndex) => {
                return parseInt(foodItem.food_group_id) === foodGroupWeb[0].id;
            });
            setFoodItems({ ...foodItems, list: temp, group: foodGroupWeb[0] });
        }
        setTimeout(() => {
            setLoading(false);
        }, 2500);

    }, [authUserInfo, navCurrencyList,]);


    // deliveryman menu update
    const deliveryMenu = () => {
        const url = BASE_URL + `/settings/deliverymen-menu-info`;
        axios.get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
            if (res.data.length == 0 || res.data[0].value == 1) {
                setdeliverymenShow(true);
            } else {
                setdeliverymenShow(false);
            }
        });

    }

    //handle jQuery
    const handleJquery = () => {
        $(window).on("scroll", function () {
            var toTopVisible = $("html").scrollTop();
            if (toTopVisible > 500) {
                $(".scrollup").fadeIn();
            } else {
                $(".scrollup").fadeOut();
            }
        });

        // MouseHover Animation home 1
        var hoverLayer = $(".banner-area");
        var heroImgOne = $(".p-shape-1");
        var heroImgTwo = $(".p-shape-2");
        var heroImgThree = $(".p-shape-3");
        var heroImgFour = $(".p-shape-4");
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 100;
            var valueY = (e.pageY * -1) / 120;
            heroImgOne.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 60;
            var valueY = (e.pageY * -1) / 80;
            heroImgTwo.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 40;
            var valueY = (e.pageY * -1) / 60;
            heroImgThree.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 80;
            var valueY = (e.pageY * -1) / 100;
            heroImgFour.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });

        // MouseHover Animation home 2
        var hoverLayer2 = $(".burger-promo-area");
        var heroImgfive = $(".bs1");
        var heroImgsix = $(".bs2");
        var heroImgseven = $(".bs5");
        var heroImgeight = $(".bs6");
        hoverLayer2.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 100;
            var valueY = (e.pageY * -1) / 120;
            heroImgfive.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer2.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 60;
            var valueY = (e.pageY * -1) / 80;
            heroImgsix.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer2.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 40;
            var valueY = (e.pageY * -1) / 60;
            heroImgseven.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer2.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 80;
            var valueY = (e.pageY * -1) / 100;
            heroImgeight.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });

        // MouseHover Animation home 3
        var hoverLayer3 = $(".snack-section");
        var heroImgnine = $(".ss1");
        var heroImgten = $(".ss2");
        var heroImgeleven = $(".ss3");
        var heroImgtweleve = $(".mss2");
        hoverLayer3.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 100;
            var valueY = (e.pageY * -1) / 120;
            heroImgtweleve.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer3.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 60;
            var valueY = (e.pageY * -1) / 80;
            heroImgnine.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer3.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 40;
            var valueY = (e.pageY * -1) / 60;
            heroImgten.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer3.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 80;
            var valueY = (e.pageY * -1) / 100;
            heroImgeleven.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
    };

    //dynamic style
    const style = {
        logo: {
            backgroundImage:
                generalSettings &&
                `url(${getSystemSettings(generalSettings, "type_logo")})`,
        },
        currency: {
            backgroundColor:
                generalSettings && getSystemSettings(generalSettings, "type_clock"),
            color:
                generalSettings && getSystemSettings(generalSettings, "type_color"),
        },
    };

    //logout
    const handleLogout = () => {
        deleteCookie();
    };

    //orders variation
    const handleOrderItemVariation = (item) => {
        setNewOrder({
            ...newOrder,
            variation: item,
        });
    };

    //property
    const handleOrderItemProperty = (propertyItem) => {
        let newTemp = [];
        if (newOrder.properties !== null && newOrder.properties.length > 0) {
            let checkExist = newOrder.properties.find((exist) => {
                return exist.id === propertyItem.id;
            });
            if (checkExist === undefined) {
                newOrder.properties.map((oldItem) => {
                    newTemp.push(oldItem);
                });
                propertyItem.quantity = 1;
                newTemp.push(propertyItem);
            } else {
                newOrder.properties.map((oldItem) => {
                    if (oldItem.id !== propertyItem.id) {
                        newTemp.push(oldItem);
                    }
                });
            }
            setNewOrder({
                ...newOrder,
                properties: newTemp,
            });
        } else {
            propertyItem.quantity = 1;
            setNewOrder({
                ...newOrder,
                properties: [propertyItem],
            });
        }
    };

    //multiple qty
    const checkedProperty = (eachItem) => {
        if (newOrder.properties !== null) {
            let findChecked = newOrder.properties.find((checkIt) => {
                return checkIt.id === eachItem.id;
            });
            if (findChecked === undefined) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    //property checked
    const checkCheckedPropertyQuantity = (propertyItem) => {
        if (newOrder.properties !== null) {
            let theItem = newOrder.properties.find((findThis) => {
                return findThis.id === propertyItem.id;
            });
            if (theItem !== undefined) {
                return theItem.quantity;
            } else {
                return 1;
            }
        } else {
            return 1;
        }
    };

    //set propertyqty
    const handlePropertyQty = (propertyItem, action) => {
        let newTemp = [];
        if (newOrder.properties !== null && newOrder.properties.length > 0) {
            newOrder.properties.map((pushThis) => {
                if (pushThis.id === propertyItem.id) {
                    if (action === "+") {
                        pushThis.quantity = pushThis.quantity + 1;
                        newTemp.push(pushThis);
                    } else {
                        if (pushThis.quantity > 1) {
                            pushThis.quantity = pushThis.quantity - 1;
                        }
                        newTemp.push(pushThis);
                    }
                } else {
                    newTemp.push(pushThis);
                }
            });
            setNewOrder({
                ...newOrder,
                properties: newTemp,
            });
        }
    };

    //get already ordered qty
    const handleAlreadyOrderedQty = (id) => {
        let temp = 0;
        if (orderDetails.items.length > 0) {
            orderDetails.items.map((item) => {
                if (parseInt(item.item.id) === id) {
                    temp += parseInt(item.quantity);
                }
            });
        }
        return temp;
    };

    //add to cart
    const handleOrder = () => {
        // check if manage stock is enable
        if (showManageStock) {
            if (
                handleGetStock(foodItems.selectedItem.id) >
                handleAlreadyOrderedQty(foodItems.selectedItem.id)
            ) {
                let tempPrice = 0;
                //if no variation
                if (newOrder.variation !== null) {
                    tempPrice = parseFloat(newOrder.variation.food_with_variation_price);
                } else {
                    tempPrice = parseFloat(foodItems.selectedItem.price);
                }

                if (newOrder.properties !== null && newOrder.properties.length > 0) {
                    let tempPropertyPrice = 0;
                    newOrder.properties.map((propertyItem, propertyItemIndex) => {
                        tempPropertyPrice =
                            tempPropertyPrice +
                            parseFloat(propertyItem.extra_price) * propertyItem.quantity;
                    });
                    tempPrice = tempPrice + tempPropertyPrice;
                }

                let tempOrderItem = {
                    item: foodItems.selectedItem,
                    quantity: newOrder.quantity,
                    variation: newOrder.variation,
                    properties: newOrder.properties,
                    subTotal: tempPrice,
                };
                setOrderDetails({
                    ...orderDetails,
                    items: [...orderDetails.items, tempOrderItem],
                });
                setShowVariation(false);
                setShowCheckout(false);
                setShowCart(true);
            } else {
                toast.error(`${_t(t("Stock Out"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            }
        } else {
            let tempPrice = 0;
            //if no variation
            if (newOrder.variation !== null) {
                tempPrice = parseFloat(newOrder.variation.food_with_variation_price);
            } else {
                tempPrice = parseFloat(foodItems.selectedItem.price);
            }

            if (newOrder.properties !== null && newOrder.properties.length > 0) {
                let tempPropertyPrice = 0;
                newOrder.properties.map((propertyItem, propertyItemIndex) => {
                    tempPropertyPrice =
                        tempPropertyPrice +
                        parseFloat(propertyItem.extra_price) * propertyItem.quantity;
                });
                tempPrice = tempPrice + tempPropertyPrice;
            }

            let tempOrderItem = {
                item: foodItems.selectedItem,
                quantity: newOrder.quantity,
                variation: newOrder.variation,
                properties: newOrder.properties,
                subTotal: tempPrice,
            };
            setOrderDetails({
                ...orderDetails,
                items: [...orderDetails.items, tempOrderItem],
            });
            setShowVariation(false);
            setShowCheckout(false);
            setShowCart(true);
        }

    };

    //stock
    const handleGetStock = (id) => {
        if (orderDetails.branch === null) {
            return 0;
        }
        let stock = foodStockWeb.find((item) => {
            return (
                parseInt(item.food_id) === parseInt(id) &&
                parseInt(item.branch_id) === parseInt(orderDetails.branch)
            );
        });
        if (stock === undefined || stock.qty < 0) {
            return 0;
        }
        return stock.qty;
    };

    //order quantity
    const handleQty = (cartItemIndex, action) => {
        let oldItems = [];
        orderDetails.items.map((orderItem, orderItemIndex) => {
            if (orderItemIndex !== cartItemIndex) {
                oldItems.push(orderItem);
            } else {
                if (action === "+") {
                    let temp = orderItem;

                    // check manage stock enable
                    if (showManageStock) {
                        let stock = handleGetStock(temp.item.id);
                        if (stock > handleAlreadyOrderedQty(temp.item.id)) {
                            temp.quantity = temp.quantity + 1;
                        } else {
                            toast.error(`${_t(t("Reached Stock Limit"))}`, {
                                position: "bottom-center",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                className: "text-center toast-notification",
                            });
                        }
                    } else {
                        temp.quantity = temp.quantity + 1;
                    }

                    let tempPrice = 0;
                    //if no variation
                    if (newOrder.variation !== null) {
                        tempPrice = parseFloat(
                            newOrder.variation.food_with_variation_price
                        );
                    } else {
                        tempPrice = parseFloat(foodItems.selectedItem.price);
                    }

                    if (newOrder.properties !== null && newOrder.properties.length > 0) {
                        let tempPropertyPrice = 0;
                        newOrder.properties.map((propertyItem, propertyItemIndex) => {
                            tempPropertyPrice =
                                tempPropertyPrice +
                                parseFloat(propertyItem.extra_price) * propertyItem.quantity;
                        });
                        tempPrice = tempPrice + tempPropertyPrice;
                    }

                    temp.subTotal = tempPrice * temp.quantity;
                    oldItems.push(temp);
                } else {
                    let temp = orderItem;
                    if (temp.quantity > 1) {
                        temp.quantity = temp.quantity - 1;
                        let tempPrice = 0;
                        //if no variation
                        if (newOrder.variation !== null) {
                            tempPrice = parseFloat(
                                newOrder.variation.food_with_variation_price
                            );
                        } else {
                            tempPrice = parseFloat(foodItems.selectedItem.price);
                        }

                        if (
                            newOrder.properties !== null &&
                            newOrder.properties.length > 0
                        ) {
                            let tempPropertyPrice = 0;
                            newOrder.properties.map((propertyItem, propertyItemIndex) => {
                                tempPropertyPrice =
                                    tempPropertyPrice +
                                    parseFloat(propertyItem.extra_price) * propertyItem.quantity;
                            });
                            tempPrice = tempPrice + tempPropertyPrice;
                        }

                        temp.subTotal = tempPrice * temp.quantity;
                        oldItems.push(temp);
                    }
                }
            }
        });
        setOrderDetails({ ...orderDetails, items: oldItems });
    };

    //calculate total
    const getTotal = () => {
        let total = 0;
        if (orderDetails.items.length > 0) {
            orderDetails.items.map((temp) => {
                total += temp.subTotal;
            });
        }
        return total;
    };

    //calculate vat
    const getVat = () => {
        let vat = 0;
        let rate = parseFloat(getSystemSettings(generalSettings, "type_vat"));
        vat = (getTotal() * rate) / 100;
        return vat;
    };

    //handle changes
    const handleChange = (e) => {
        e.preventDefault();
        setOrderDetails({
            ...orderDetails,
            [e.target.name]: e.target.value,
        });
    };
    // stripe payment states
    const [stripeDetails, setstripeDetails] = useState({
        stripe_card_name: null,
        stripe_card_number: null,
        stripe_card_cvc: null,
        stripe_card_exp_month: null,
        stripe_card_exp_year: null,
        stripe_zip_code: null,
    });
    //submit order cod
    const handleOrderSubmit = (e) => {
        setOrderDetails({
            ...orderDetails,
            uploading: true,
        });
        e.preventDefault();
        let url = BASE_URL + "/website/order";
        let formData = orderDetails;
        formData.subTotal = getTotal();
        formData.vat = getVat();
        formData.delivery_charge = branchDeliveryCharge;
        return axios
            .post(url, orderDetails, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                if (res.data !== "ended") {
                    setShowCheckout(false);
                    setFoodStockWeb(res.data);
                    setOrderDetails({
                        items: [],
                        branch: null,
                        workPeriod: null,
                        workPeriodStatus: false,
                        address: authUserInfo.details && authUserInfo.details.address,
                        name: authUserInfo.details && authUserInfo.details.name,
                        phn_no: authUserInfo.details && authUserInfo.details.phn_no,
                        note: null,
                        payment_type: "COD",
                        uploading: false,
                    });
                    toast.success(`${_t(t("Your order has been placed"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                } else {
                    toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                    setOrderDetails({
                        ...orderDetails,
                        uploading: false,
                    });
                }
            })
            .catch((err) => {
                setOrderDetails({
                    ...orderDetails,
                    uploading: false,
                });
                toast.error(`${_t(t("Please try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            });
    };

    // handle stripe on change
    const onchangehandleStripePayment = (e) => {
        setstripeDetails({ ...stripeDetails, [e.target.name]: e.target.value })
    };

    // stripe handle submit
    const onsubmithandleStripePayment = (e) => {
        e.preventDefault();
        document.getElementById('stripePaynow').innerHTML = "Please wait...";
        let url = BASE_URL + "/settings/get-stripe-data";

        let formdata = stripeDetails;
        formdata.subTotal = getTotal();
        formdata.vat = getVat();
        formdata.delivery_charge = branchDeliveryCharge;
        return axios
            .post(url, formdata, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                // make reuseble payment component
                if (res.data.status == 422) {
                    document.getElementById('stripePaynow').innerHTML = "Pay Now";
                    toast.error(`${_t(t("Please enter valid info"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                } else {
                    const payment_id = res.data.card.id;
                    const payment_type = "STRIPE";

                    // send data to serve start
                    setOrderDetails({
                        ...orderDetails,
                        uploading: true,
                    });
                    e.preventDefault();
                    let url = BASE_URL + "/website/order";
                    let formData = orderDetails;
                    formData.subTotal = getTotal();
                    formData.vat = getVat();
                    formData.payment_type = payment_type;
                    formData.payment_id = payment_id;
                    formData.delivery_charge = branchDeliveryCharge;
                    return axios
                        .post(url, orderDetails, {
                            headers: { Authorization: `Bearer ${getCookie()}` },
                        })
                        .then((res) => {
                            if (res.data !== "ended") {
                                setShowCheckout(false);
                                setFoodStockWeb(res.data);
                                setOrderDetails({
                                    items: [],
                                    branch: null,
                                    workPeriod: null,
                                    workPeriodStatus: false,
                                    address: authUserInfo.details && authUserInfo.details.address,
                                    name: authUserInfo.details && authUserInfo.details.name,
                                    phn_no: authUserInfo.details && authUserInfo.details.phn_no,
                                    note: null,
                                    payment_type: "COD",
                                    uploading: false,
                                });
                                toast.success(`${_t(t("Your order has been placed"))}`, {
                                    position: "bottom-center",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    className: "text-center toast-notification",
                                });
                            } else {
                                toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
                                    position: "bottom-center",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    className: "text-center toast-notification",
                                });
                                setOrderDetails({
                                    ...orderDetails,
                                    uploading: false,
                                });
                            }
                            document.getElementById('stripePaynow').innerHTML = "Pay Now";
                        })
                        .catch((err) => {
                            setOrderDetails({
                                ...orderDetails,
                                uploading: false,
                            });
                            toast.error(`${_t(t("Please try again"))}`, {
                                position: "bottom-center",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                className: "text-center toast-notification",
                            });
                            document.getElementById('stripePaynow').innerHTML = "Pay Now";
                        });
                    // send data to serve end
                }

            })
            .catch((err) => {
                console.log(err);
            });
    };

    //set default language on site load
    const handleOnLoadDefaultLang = () => {
        let localLang = localStorage.i18nextLng;
        if (localLang) {
            if (localLang === undefined || localLang.includes("en-")) {
                navLanguageList &&
                    navLanguageList.map((item) => {
                        if (item.is_default === true) {
                            i18n.changeLanguage(item.code);
                            setDefaultLang(item);
                        }
                        return true;
                    });
            } else {
                const temp =
                    navLanguageList &&
                    navLanguageList.find((item) => {
                        return item.code === localLang;
                    });
                setDefaultLang(temp);
                i18n.changeLanguage(localLang);
            }
        }
    };

    //change language to selected
    const handleDefaultLang = (e) => {
        let lang =
            navLanguageList &&
            navLanguageList.find((theItem) => {
                return theItem.id === parseInt(e.target.value);
            });
        i18n.changeLanguage(lang.code);
        setDefaultLang(lang);
        toast.success(`${_t(t("Language has been switched!"))}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
    };

    //set default currency on site load
    const handleOnLoadDefaultCurrency = () => {
        let localCurrency = JSON.parse(localStorage.getItem("currency"));
        if (localCurrency === null) {
            navCurrencyList &&
                navCurrencyList.map((item) => {
                    if (item.is_default === true) {
                        setDefaultCurrency(item);
                        localStorage.setItem("currency", JSON.stringify(item));
                    }
                    return true;
                });
        } else {
            const temp =
                navCurrencyList &&
                navCurrencyList.find((item) => {
                    return item.code === localCurrency.code;
                });
            setDefaultCurrency(temp);
        }
    };

    //change currency to selected
    const handleDefaultCurrency = (e) => {
        let item =
            navCurrencyList &&
            navCurrencyList.find((theItem) => {
                return theItem.id === parseInt(e.target.value);
            });
        localStorage.setItem("currency", JSON.stringify(item));
        setDefaultCurrency(item);
        toast.success(`${_t(t("Currency has been changed!"))}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
    };

    //get selected branch
    const getSelectedBranch = (id) => {
        if (orderDetails && orderDetails.branch !== null) {
            if (id === orderDetails.branch.id) {
                return true;
            }
        }
        return false;
    };

    //paypal integration
    const initialOptions = {
        // "client-id": { paypal_client_id },
        "client-id": "AWOafqislzl8zx6-w5BwIOu9p-7DXKNt3Ly4hGzXYNRYBKJkY_yrUcAYSc5RP6YFz_ckikuYoDoBs9NK",
        currency: "USD",
        intent: "capture",
    };

    return (
        <>
            <Helmet>
                <title>
                    {generalSettings && getSystemSettings(generalSettings, "siteName")}
                </title>
                <link rel="stylesheet" href="/website/css/animate.css" />
                <link rel="stylesheet" href="/website/css/meanmenu.min.css" />
                <link rel="stylesheet" href="/website/css/bootstrap.min.css" />
                <link rel="stylesheet" href="/website/css/font-awsome-all.min.css" />
                <link rel="stylesheet" href="/website/css/magnific-popup.css" />
                <link rel="stylesheet" href="/website/css/slick.css" />
                <link rel="stylesheet" href="/website/css/jquery-ui.css" />
                <link rel="stylesheet" href="/website/css/style.css" />

                <script src="/website/js/vendor/jquery-2.2.4.min.js"></script>
                <script src="/website/js/vendor/bootstrap.min.js"></script>
                <script src="/website/js/vendor/jquery.meanmenu.min.js"></script>
                <script src="/website/js/vendor/jquery.magnific-popup.min.js"></script>
                <script src="/website/js/vendor/slick.min.js"></script>
                <script src="/website/js/vendor/counterup.min.js"></script>
                <script src="/website/js/vendor/countdown.js"></script>
                <script src="/website/js/vendor/waypoints.min.js"></script>
                <script src="/website/js/vendor/jquery-ui.js"></script>
                <script src="/website/js/vendor/isotope.pkgd.min.js"></script>
                <script src="/website/js/vendor/easing.min.js"></script>
                <script src="/website/js/vendor/wow.min.js"></script>
                <script src="/website/js/simplebar.js"></script>
                <script src="/website/js/main.js"></script>
            </Helmet>

            {/* <!-- Preloader Starts --> */}
            <div className={`preloader02 ${!loading && "d-none"}`} id="preloader02">
                <div className="preloader-inner">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
            </div>

            {/* template content section */}
            <div className={loading ? "d-none" : ""}>
                <header className="header3">
                    {/* header-bottom  */}
                    <div className="header-bottom home2-header-bottom margin-top-20">
                        <div className="container position-relative">
                            <div className="row d-flex align-items-center">
                                <div className="col-lg-2 col-md-2 col-sm-4 col-8 margin-bottom-20">
                                    <div className="logo">
                                        {window.location.pathname === "/" ? (
                                            <NavLink
                                                to={{ pathname: "/refresh", state: "/" }}
                                                exact
                                                className="t-link w-100"
                                                key="logokey"
                                            >
                                                <img
                                                    src={getSystemSettings(generalSettings, "type_logo")}
                                                    alt="logo"
                                                />
                                            </NavLink>
                                        ) : (
                                            <NavLink
                                                to="/"
                                                exact
                                                className="t-link w-100"
                                                key="logokey"
                                            >
                                                <img
                                                    src={getSystemSettings(generalSettings, "type_logo")}
                                                    alt="logo"
                                                />
                                            </NavLink>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-7 d-none d-lg-block">
                                    <nav id="mobile-menu">
                                        <ul className="main-menu main-menu3">
                                            <li>
                                                <a href="/">{_t(t("home"))}</a>
                                            </li>
                                            <li>
                                                <a href="/menu">{_t(t("menu"))}</a>
                                            </li>
                                            <li>
                                                <a href="/gallery">{_t(t("Gallery"))}</a>
                                            </li>
                                            {authUserInfo &&
                                                authUserInfo.details &&
                                                authUserInfo.details.user_type === "customer" && (
                                                    <li>
                                                        <NavLink to="/profile">{_t(t("Profile"))}</NavLink>
                                                    </li>
                                                )}

                                            {deliverymenShow == true && authUserInfo && !authUserInfo.details && (
                                                <li>
                                                    <NavLink to="/delivery-man-registration">
                                                        {_t(t("deliveryman"))}
                                                    </NavLink>
                                                </li>
                                            )}
                                        </ul>
                                    </nav>
                                </div>
                                <div className="col-lg-3 col-md-9 col-sm-8 col-12">
                                    <div className="customer-area3 d-flex align-items-center justify-content-center justify-content-md-end">
                                    {/*<span className="position-relative">*/}
                                    {/*    <a className="shopping" href="shopping-cart.html"><i*/}
                                    {/*        className="fas fa-shopping-basket"></i></a>*/}
                                    {/*    <span className="shop-counter">1</span>*/}
                                    {/*</span>*/}
                                        {getCookie() === undefined ? (
                                            <NavLink to="/login" className="btn2">
                                                {_t(t("Login"))}
                                            </NavLink>
                                        ) : (
                                            <>
                                                {authUserInfo &&
                                                authUserInfo.details &&
                                                authUserInfo.details.user_type !== "customer" ? (
                                                    <NavLink to="/dashboard" className="btn2">
                                                        {_t(t("Dashboard"))}
                                                    </NavLink>
                                                ) : (
                                                    <button className="btn2" onClick={handleLogout}>
                                                        {_t(t("Logout"))}
                                                    </button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>


                <div className="banner-area breadcrumb-area padding-top-120 padding-bottom-90">
                    <div className="bread-shapes">
                        <span className="b-shape-1 item-bounce"><img src="website/images/img/5.png" alt=""/></span>
                        <span className="b-shape-2"><img src="website/images/img/6.png" alt=""/></span>
                        <span className="b-shape-3"><img src="website/images/img/7.png" alt=""/></span>
                        <span className="b-shape-4"><img src="website/images/img/9.png" alt=""/></span>
                        <span className="b-shape-5"><img src="website/images/shapes/18.png" alt=""/></span>
                        <span className="b-shape-6 item-animateOne"><img src="website/images/img/7.png" alt=""/></span>
                    </div>
                    <div className="container padding-top-120">
                        <div className="row justify-content-center">
                            <nav aria-label="breadcrumb text-center">
                                <h2 className="page-title">blog single</h2>
                                <ol className="breadcrumb text-center">
                                    <li className="breadcrumb-item"><a href="index.html">Home</a>/<a href="index.html">pages</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">blog single page</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>


                <section className="blog-content-section blog-details-page padding-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="blog-posts">
                                    <div className="blog-single-post">
                                        <div className="blog-thumbnail wow fadeInUp">
                                            <img src="website/images/blog/blog2.jpg" alt="thumbnail"/>
                                        </div>
                                        <div className="blog-content-part">
                                            <div className="blog-content-top">
                                                <div className="blog-date margin-right-20">
                                                    <a href="#"><i className="fas fa-tag"></i> Chicken Burger</a>
                                                </div>
                                                <div className="blog-tag margin-right-20">
                                                    <a href="#"><i className="fas fa-user-circle"></i>Miyako Prince</a>
                                                </div>
                                                <div className="blog-author">
                                                    <a href="#"><i className="fas fa-comments"></i> 120+ Comment</a>
                                                </div>
                                            </div>
                                            <div className="blog-title">
                                                <h4>A Burger Worthy of the Presidnet at Upland</h4>
                                            </div>
                                            <div className="blog-content wow fadeInUp">
                                                <p>Organizations today are in constant flux. Industries are consolidating, business
                                                    models are emerging, new technologies are being developed, and consumer
                                                    behaviors are evolving. It forces them to understand and quickly respond to big
                                                    shifts in the way companies operate and how work must get done. In the words of
                                                    Arie de Geus, a business theorist, The ability to learn faster than your
                                                    competitors may be the only sustainable competitive advantage.</p>
                                                <p className="margin-top-20">I’m not talking about relaxed armchair or even structured
                                                    classroom learning. I’m talking about resisting the bias against doing new
                                                    things, scanning the horizon for growth opportunities, and pushing yourse
                                                    radically different capabilities—while still performing your job. That requires
                                                    a willingness to experiment and become a novice again and again: an extremely
                                                    discomforting notion for most of us.</p>
                                                <p className="margin-top-20">Lorem ipsum dolor sit amet, consectet adipiscing elit, sed
                                                    do eiusmod tempor incididunt ut labore et dolore magnag aliqua. Ut enim ad minim
                                                    veniam, quis nostrud exercitation ullamco laborinu aliquip ex ea commodo
                                                    consequat. Du aute irure dolor in reprehenderit inlore voluptate velit esse
                                                    cillum dolore.</p>
                                            </div>
                                            <div className="blog-title">
                                                <h5>New Restaurant Town</h5>
                                            </div>
                                            <div className="blog-content  wow fadeInUp">
                                                <p>We’ve identified four attributes they have in spades: aspiration, self-awareness,
                                                    curiosity, and vulnerability. They truly want to understand and master new
                                                    skills; they see themselves very clearly; they constantly think of and ask good
                                                    questions; and they tolerate their own mistakes as they move up the learning
                                                    curve.</p>
                                                <div className="content-list margin-top-20">
                                                    <div className="row">
                                                        <div className="col-sm-6"><span className="single-list"><i
                                                            className="fa fa-arrow-circle-right"></i>Etiam commodo ultricies
                                                    luctus.</span></div>
                                                        <div className="col-sm-6"><span className="single-list"><i
                                                            className="fa fa-arrow-circle-right"></i>Consectetur adipisicing
                                                    elit.</span></div>
                                                        <div className="col-sm-6"><span className="single-list"><i
                                                            className="fa fa-arrow-circle-right"></i>Anim pariatur cliche
                                                    reprehenderit.</span></div>
                                                        <div className="col-sm-6"><span className="single-list"><i
                                                            className="fa fa-arrow-circle-right"></i>Investig ationes demons
                                                    trave.</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="blog-images margin-top-30">
                                                <div className="row">
                                                    <div className="col-sm-6 wow fadeInLeft">
                                                        <div className="single-image">
                                                            <img src="website/images/blog/blog4.jpg" alt="image"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 wow fadeInRight">
                                                        <div className="row">
                                                            <div className="col-sm-12 margin-bottom-30">
                                                                <div className="single-image image-2">
                                                                    <img src="website/images/blog/blog5.jpg" alt="image"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="single-image ">
                                                                    <img src="website/images/blog/blog6.jpg" alt="image"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="single-image image-2">
                                                                    <img src="website/images/blog/blog7.jpg" alt="image"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="blog-content  wow fadeInUp">
                                                <p>We’ve identified four attributes they have in spades: aspiration, self-awareness,
                                                    curiosity, and vulnerability. They truly want to understand and master new
                                                    skills; they see themselves very clearly; they constantly think of and ask good
                                                    questions; and they tolerate their own mistakes as they move up the learning
                                                    curve.</p>
                                            </div>
                                            <div className="blog-quote margin-top-30 margin-bottom-30  wow fadeInUp">
                                                <div className="quote-text">
                                                    <p>A hamburger is a sandwich consisting of one or more cooked
                                                        patties of ground meat usually beef, placed inside a sliced bread roll or
                                                        bun.The patty may be pan fried.</p>
                                                    <h6>tonmoy khan</h6>
                                                </div><span className="quote-icon"><i className="fa fa-quote-right"></i></span>
                                            </div>
                                            <div className="blog-title">
                                                <h4>Make Yourself Accountable</h4>
                                            </div>
                                            <div className="blog-content  wow fadeInUp">
                                                <p>We’ve identified four attributes they have in spades: aspiration, self-awareness,
                                                    curiosity, and vulnerability. They truly want to understand and master new
                                                    skills; they see themselves very clearly; they constantly think of and ask good
                                                    questions; and they tolerate their own mistakes as they move up the learning
                                                    curve.</p>
                                                <p className="margin-top-20">We’ve identified four attributes they have in spades:
                                                    aspiration, self-awareness,curiosity, and vulnerability. They truly want to
                                                    understand and master new skills; they see themselves very clearly.</p>
                                            </div>
                                            <div className="blog-thumbnail wow fadeInUp margin-top-30">
                                                <img src="website/images/blog/blog4.jpg" alt="image"/>
                                            </div>
                                            <div className="blog-contnet margin-top-30  wow fadeInUp">
                                                <p>We’ve identified four attributes they have in spades: aspiration, self-awareness,
                                                    curiosity, and vulnerability. They truly want to understand and master new
                                                    skills; they see themselves very clearly; they constantly think of and ask good
                                                    questions; and they tolerate their own mistakes as they move up the learning
                                                    curve.</p>
                                                <p className="margin-top-20">We’ve identified four attributes they have in spades:
                                                    aspiration, self-awareness, curiosity, and vulnerability. They truly want to
                                                    understand and master new skills; they see themselves very clearly.</p>
                                            </div>
                                            <div className="content-bottom margin-top-30">
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="content-bottom-tag">
                                                            <ul>
                                                                <li>tags:</li>
                                                                <li><a href="#">bbq</a></li>
                                                                <li><a href="#">tikka</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="blog-social-icons">
                                                            <ul>
                                                                <li><span>share:</span></li>
                                                                <li><a href="#"><img src="website/images/icons/facebook.png"
                                                                                     alt=""/></a>
                                                                </li>
                                                                <li><a href="#"><img src="website/images/icons/twitter.png"
                                                                                     alt=""/></a>
                                                                </li>

                                                                <li><a href="#"><img src="website/images/icons/inst.png" alt=""/></a>
                                                                </li>
                                                                <li><a href="#"><img src="website/images/icons/linkdin.png"
                                                                                     alt=""/></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="divider"></div>



                                    <div className="blog-comment-area  wow fadeInUp">
                                        <div className="blog-title margin-top-30">
                                            <h3>2 comments</h3>
                                        </div>

                                        <div className="single-comment">
                                            <div className="comment-image">
                                                <img src="website/images/testimonial/testi-1.png" alt="author"/>
                                            </div>
                                            <div className="comment-content">
                                                <div className="comment-content-top">
                                                    <div className="content-top-left">
                                                        <h6>Rumon Khan</h6>
                                                        <span>30 august 2020</span>
                                                    </div>
                                                    <a href="#" className="reply">reply</a>
                                                </div>
                                                <p className="margin-top-10">A wonderful serenity has taken possession of my entire
                                                    soul, like these sweet mornings of the spring which I enjoy with my whole heart.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="single-comment">
                                            <div className="comment-image">
                                                <img src="website/images/testimonial/test-4.png" alt="author"/>
                                            </div>
                                            <div className="comment-content">
                                                <div className="comment-content-top">
                                                    <div className="content-top-left">
                                                        <h6>Chirs Deo</h6>
                                                        <span>30 august 2020</span>
                                                    </div>
                                                    <a href="#" className="reply bg-secondary">reply</a>
                                                </div>
                                                <p className="margin-top-10">A wonderful serenity has taken possession of my entire
                                                    soul, like these sweet mornings of the spring which I enjoy with my whole heart.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="blog-comment-form margin-top-60  wow fadeInUp">
                                        <div className="comment-title">
                                            <h4>leave a reply</h4>
                                            <form action="#">
                                                <input type="text" placeholder="Your Name"/>
                                                    <input type="email" placeholder="Your Email"/>
                                    <textarea name="message" placeholder="Write your text" id="comment-form" cols="30"
                                              rows="10"></textarea>
                                                        <button type="submit" className="btn margin-top-40">post comment</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="blog-sidebar">
                                    <div className="single-widget author-widget  wow fadeInUp">
                                        <img src="website/images/blog/user.jpg" alt="image"/>
                                            <div className="author-name margin-top-20">
                                                <h5>Miyako Prince</h5>
                                            </div>
                                            <div className="author-content margin-top-10">
                                                <p> Hi! I'm author of this post. Read my post, be in trend.</p>
                                            </div>
                                            <div className="author-social-link margin-top-20">
                                                <ul>
                                                    <li><a href="#"><i className="fab fa-facebook"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                                    <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                                                </ul>
                                            </div>
                                    </div>
                                    <div className="single-widget search-widget  wow fadeInUp">
                                        <div className="header-search">
                                            <form action="#">
                                                <input type="text" placeholder="Search"/>
                                                    <button type="submit"><i className="fa fa-search"></i></button>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="single-widget product-widget  wow fadeInUp">
                                        <h6 className="margin-bottom-10">Hot Sale Products</h6>
                                        <div className="single-product d-flex align-items-center">
                                            <div className="dish-img">
                                                <a href="single-food.html"><img src="website/images/menu-item/pd6.png" alt=""/></a>
                                            </div>
                                            <div className="dish-content">
                                                <h6> <a href="single-food.html">Chicken Fry
                                                    Recipe
                                                </a></h6>
                                                <span className="price">$10.00</span>
                                                <span>
                                        <a className="cart-select" href="shopping-cart.html"><i
                                            className="fas fa-shopping-basket"></i></a>
                                    </span>
                                            </div>
                                        </div>
                                        <div className="single-product d-flex align-items-center">
                                            <div className="dish-img">
                                                <a href="single-food.html"> <img src="website/images/menu-item/pd4.png" alt=""/></a>
                                            </div>
                                            <div className="dish-content">
                                                <h6> <a href="single-food.html">Chicken Soup
                                                    Rejala
                                                </a></h6>
                                                <span className="price">$20.00</span>
                                                <span>
                                        <a className="cart-select" href="shopping-cart.html"><i
                                            className="fas fa-shopping-basket"></i></a>
                                    </span>
                                            </div>
                                        </div>
                                        <div className="single-product d-flex align-items-center">
                                            <div className="dish-img">
                                                <a href="single-food.html"> <img src="website/images/menu-item/pd7.png" alt=""/></a>
                                            </div>
                                            <div className="dish-content">
                                                <h6> <a href="single-food.html">The Best Black
                                                    Frame
                                                </a></h6>
                                                <span className="price">$80.00</span>
                                                <span>
                                        <a className="cart-select" href="shopping-cart.html"><i
                                            className="fas fa-shopping-basket"></i></a>
                                    </span>
                                            </div>
                                        </div>
                                        <div className="single-product d-flex align-items-center">
                                            <div className="dish-img">
                                                <a href="single-food.html"> <img src="website/images/menu-item/pd8.png" alt=""/></a>
                                            </div>
                                            <div className="dish-content">
                                                <h6> <a href="single-food.html">The Best Black
                                                    Frame
                                                </a></h6>
                                                <span className="price">$80.00</span>
                                                <span>
                                        <a className="cart-select" href="shopping-cart.html"><i
                                            className="fas fa-shopping-basket"></i></a>
                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-widget tag-widget  wow fadeInUp">
                                        <div className="widget-title">
                                            <h5>tags.</h5>
                                        </div>
                                        <div className="tag-items">
                                            <ul>
                                                <li><a href="#">bbq</a></li>
                                                <li><a href="#">tikka</a></li>
                                                <li><a href="#">pizza</a></li>
                                                <li><a href="#">chicken sharma</a></li>
                                                <li><a href="#">garlic burger</a></li>
                                                <li><a href="#">sandwich</a></li>
                                                <li><a href="#">chicken soup</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="single-widget filter-menu-widget  wow fadeInUp">
                                        <h6><a href="#">filter by menu</a></h6>
                                        <div className="filter-menu d-flex align-items-center">
                                            <div className="dish-img">
                                                <a href="single-dish.html"> <img src="website/images/menu-item/fm1.png" alt=""/></a>
                                            </div>
                                            <div className="dish-content d-flex justify-content-between">
                                                <h6> <a href="single-dish.html">burger food
                                                </a></h6>
                                                <span className="price">20</span>
                                            </div>
                                        </div>
                                        <div className="filter-menu d-flex align-items-center">
                                            <div className="dish-img">
                                                <a href="single-dish.html"> <img src="website/images/menu-item/fm2.png" alt=""/></a>
                                            </div>
                                            <div className="dish-content d-flex justify-content-between">
                                                <h6> <a href="single-dish.html">Chicken Fry
                                                </a></h6>
                                                <span className="price">50</span>
                                            </div>
                                        </div>
                                        <div className="filter-menu d-flex align-items-center">
                                            <div className="dish-img">
                                                <a href="single-dish.html"> <img src="website/images/menu-item/fm3.png" alt=""/></a>
                                            </div>
                                            <div className="dish-content d-flex justify-content-between">
                                                <h6> <a href="single-dish.html">Pizza
                                                </a></h6>
                                                <span className="price">20</span>
                                            </div>
                                        </div>
                                        <div className="filter-menu d-flex align-items-center">
                                            <div className="dish-img">
                                                <a href="single-dish.html"> <img src="website/images/menu-item/fm4.png" alt=""/></a>
                                            </div>
                                            <div className="dish-content d-flex">

                                                <h6> <a href="single-dish.html">Chicken fry
                                                </a></h6>

                                                <span className="price">50</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-widget banner-widget  wow fadeInUp">
                                        <div className="gallery-img-4">
                                            <h5>Super Combo
                                                Burger</h5>
                                            <a href="shopping-cart.html" className="btn">order now</a>
                                            <img src="website/images/gallery/22.png" alt=""/>
                                                <span className="gprice-4"><img src="website/images/gallery/25.png" alt=""/></span>
                                                <span className="gshape-1"><img src="website/images/shapes/35.png" alt=""/></span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <footer className="ht-footer padding-bottom-55 padding-top-220">
                    <div className="htf-shapes">
                        <img className="drs1 item-bounce d-none d-md-block" src="website/images/img/htshape22.png"
                             alt=""/>
                            <img className="drs2 d-none d-md-block" src="website/images/img/htshape17.png" alt=""/>
                                <img className="drs3 d-none d-md-block" src="website/images/img/htshape2.png" alt=""/>
                                    <img className="drs4 item-animateTwo d-none d-md-block"
                                         src="website/images/img/htleaf2.png" alt=""/>
                    </div>
                    <div className="container">
                        <div className="ht-footer-top">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 margin-bottom-30">
                                    <div className="widget text-center text-sm-left">
                                        <div className="logo">
                                            <NavLink
                                                to={{ pathname: "/refresh", state: "/" }}
                                                exact
                                                className="t-link w-100"
                                                key="logokey"
                                            >
                                                <img
                                                    src={getSystemSettings(generalSettings, "type_logo")}
                                                    alt="logo"
                                                />
                                            </NavLink>
                                        </div>
                                        <ul>
                                            <li>570 8th Ave,New York, NY 10018
                                                United States
                                            </li>
                                        </ul>
                                        <a href="#" className="ht-link">google map</a>
                                        <div className="social-box">
                                            <span><a href="#"><i className="fab fa-facebook"></i></a></span>
                                            <span><a href="#"><i className="fab fa-instagram"></i></a></span>
                                            <span><a href="#"><i className="fab fa-twitter"></i></a></span>
                                            <span><a href="#"><i className="fab fa-youtube"></i></a></span>
                                            <span><a href="#"><i className="fab fa-linkedin"></i></a></span>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 margin-bottom-30">
                                    <div className="widget text-center text-sm-left">
                                        <h6>delivery support</h6>
                                        <h5><span><i className="fas fa-phone-square-alt"></i></span>123-59794069</h5>
                                        <ul>
                                            <li>Monday – Friday: 9:00-20:00
                                            </li>
                                            <li>Saturday: 11:00 – 15:00</li>
                                        </ul>
                                        <a href="#" className="ht-link">email address</a>
                                        <a href="#" className="ht-email"><span><i
                                            className="fas fa-envelope-open-text"></i></span>
                                            infofoodkahn@gmail.com</a>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 margin-bottom-30">
                                    <div className="widget text-center text-sm-left">
                                        <h6>information</h6>
                                        <ul>
                                            <li><a href="about.html">about us</a></li>
                                            <li><a href="#">delivery information</a></li>
                                            <li><a href="#">privacy policy</a></li>
                                            <li><a href="#">sales</a></li>
                                            <li><a href="#">terms & conditions</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 margin-bottom-30">
                                    <div className="widget text-center text-sm-left">
                                        <h6>newsletter</h6>
                                        <div className="newsletter newsletter-3 d-flex">
                                            <form action="#">
                                                <input type="email" placeholder="enter your email"/>
                                                    <button type="submit"><i
                                                        className="fas fa-paper-plane"></i></button>
                                            </form>
                                        </div>
                                        <a href="#" className="btn2">subscribe</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*section divider*/}

                        <div className="divider">
                            <hr/>
                        </div>

                        <div className="ht-footer-bottom">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-4 col-12">
                                    <div className="ht-copyright-area text-center text-md-left">
                                        <p>copyright @ 2021 <a href="index.html">Tech Performance USA</a></p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-8 col-12">
                                    <div className="ht-payment">
                                        <a href="checkout.html"> <img src="website/images/img/payment2.png" alt=""/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                {/* <!-- ToTop Button --> */}
                <button
                    className="scrollup scrollup2"
                    onClick={() => {
                        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                >
                    <i className="fas fa-angle-up"></i>
                </button>
            </div>
            {/* template content end here */}
        </>
    );
};

export default BlogSingle;

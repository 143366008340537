import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

//importing context consumer here
import { UserContext } from "../../contexts/User";
import { FoodContext } from "../../contexts/Food";
import { RestaurantContext } from "../../contexts/Restaurant";
import { SettingsContext } from "../../contexts/Settings";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../src/BaseUrl";

//functions
import {
    _t,
    modalLoading,
    restaurantMenuLink,
    getSystemSettings,
    getCookie,
    deleteCookie,
    currencySymbolLeft,
    formatPrice,
    currencySymbolRight,
} from "../../functions/Functions";

//3rd party packages
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//jQuery initialization
import $ from "jquery";

// template file
import Testimonial from "./Testimonial";
import Gallery from "./Gallery";
import Blog from "./Blog";
import DeliverySection from "./DeliverySection";
import Countdown from "./Countdown";
import Banner from "./Banner";
import About from "./About";
import Header from "./Header";
import Footer from "./Footer";


const Catering = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    //getting context values here
    let {
        navLanguageList,
        navCurrencyList,
        generalSettings,
        showManageStock,
        paypal_client_id
    } =
        useContext(SettingsContext);
    //auth user
    const { authUserInfo } = useContext(UserContext);
    //restaurant
    let { branchForSearch } = useContext(RestaurantContext);
    //food
    let {
        getFoodWeb,
        foodListWeb,
        foodGroupWeb,
        propertyGroupWeb,
        workPeriodWeb,
        foodStockWeb,
        setFoodStockWeb,
    } = useContext(FoodContext);

    //use state
    const [loading, setLoading] = useState(true);
    const [showCart, setShowCart] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);
    const [showVariation, setShowVariation] = useState(false);

    // stripe
    const [showStripe, setshowStripe] = useState('');

    const [deliverymenShow, setdeliverymenShow] = useState(false);
    const [checkLoginUser, setCheckLoginUser] = useState(0);
    //
    const [defaultLang, setDefaultLang] = useState(null);
    const [defaultCurrency, setDefaultCurrency] = useState(null);

    // branch delivery charge
    const [branchDeliveryCharge, setBranchDeliveryCharge] = useState(0);

    //food and group
    const [foodItems, setFoodItems] = useState({
        list: null,
        group: null,
        selectedItem: null,
        variations: null,
        properties: null,
    });

    //new order
    const [newOrder, setNewOrder] = useState({
        variation: null,
        quantity: 1,
        properties: null,
    });

    //order details
    const [orderDetails, setOrderDetails] = useState({
        items: [],
        branch: null,
        workPeriod: null,
        workPeriodStatus: false,
        address: null,//null
        name: null,
        phn_no: null,
        note: null,
        payment_type: "COD",
        uploading: false,
    });

    // check auth user 
    const checkLoginfunc = () => {
        getCookie() !== undefined ? setCheckLoginUser(0) : setCheckLoginUser(1);
    }

    // show stripe 
    const showstripebtn = (e) => {
        setLoading(true);
        const url = BASE_URL + `/settings/show-stripe-btn`;
        return axios
            .get(url)
            .then((res) => {
                setshowStripe(res.data);
                setLoading(false);
            })
    }

    //useeffect == componentDidMount()
    useEffect(() => {
        handleJquery();
        getFoodWeb();
        deliveryMenu();
        handleOnLoadDefaultLang();
        handleOnLoadDefaultCurrency();
        checkLoginfunc();
        showstripebtn();
        setOrderDetails({
            ...orderDetails,
            address: authUserInfo.details && authUserInfo.details.address,
            name: authUserInfo.details && authUserInfo.details.name,
            phn_no: authUserInfo.details && authUserInfo.details.phn_no,
        });
        if (foodGroupWeb && foodListWeb) {
            let temp = foodListWeb.filter((foodItem, foodItemIndex) => {
                return parseInt(foodItem.food_group_id) === foodGroupWeb[0].id;
            });
            setFoodItems({ ...foodItems, list: temp, group: foodGroupWeb[0] });
        }
        setTimeout(() => {
            setLoading(false);
        }, 2500);

    }, [authUserInfo, navCurrencyList,]);


    // deliveryman menu update
    const deliveryMenu = () => {
        const url = BASE_URL + `/settings/deliverymen-menu-info`;
        axios.get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
            if (res.data.length == 0 || res.data[0].value == 1) {
                setdeliverymenShow(true);
            } else {
                setdeliverymenShow(false);
            }
        });

    }

    //handle jQuery
    const handleJquery = () => {
        $(window).on("scroll", function () {
            var toTopVisible = $("html").scrollTop();
            if (toTopVisible > 500) {
                $(".scrollup").fadeIn();
            } else {
                $(".scrollup").fadeOut();
            }
        });

        // MouseHover Animation home 1
        var hoverLayer = $(".banner-area");
        var heroImgOne = $(".p-shape-1");
        var heroImgTwo = $(".p-shape-2");
        var heroImgThree = $(".p-shape-3");
        var heroImgFour = $(".p-shape-4");
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 100;
            var valueY = (e.pageY * -1) / 120;
            heroImgOne.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 60;
            var valueY = (e.pageY * -1) / 80;
            heroImgTwo.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 40;
            var valueY = (e.pageY * -1) / 60;
            heroImgThree.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 80;
            var valueY = (e.pageY * -1) / 100;
            heroImgFour.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });

        // MouseHover Animation home 2
        var hoverLayer2 = $(".burger-promo-area");
        var heroImgfive = $(".bs1");
        var heroImgsix = $(".bs2");
        var heroImgseven = $(".bs5");
        var heroImgeight = $(".bs6");
        hoverLayer2.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 100;
            var valueY = (e.pageY * -1) / 120;
            heroImgfive.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer2.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 60;
            var valueY = (e.pageY * -1) / 80;
            heroImgsix.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer2.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 40;
            var valueY = (e.pageY * -1) / 60;
            heroImgseven.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer2.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 80;
            var valueY = (e.pageY * -1) / 100;
            heroImgeight.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });

        // MouseHover Animation home 3
        var hoverLayer3 = $(".snack-section");
        var heroImgnine = $(".ss1");
        var heroImgten = $(".ss2");
        var heroImgeleven = $(".ss3");
        var heroImgtweleve = $(".mss2");
        hoverLayer3.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 100;
            var valueY = (e.pageY * -1) / 120;
            heroImgtweleve.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer3.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 60;
            var valueY = (e.pageY * -1) / 80;
            heroImgnine.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer3.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 40;
            var valueY = (e.pageY * -1) / 60;
            heroImgten.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer3.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 80;
            var valueY = (e.pageY * -1) / 100;
            heroImgeleven.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
    };

    //dynamic style
    const style = {
        logo: {
            backgroundImage:
                generalSettings &&
                `url(${getSystemSettings(generalSettings, "type_logo")})`,
        },
        currency: {
            backgroundColor:
                generalSettings && getSystemSettings(generalSettings, "type_clock"),
            color:
                generalSettings && getSystemSettings(generalSettings, "type_color"),
        },
    };

    //logout
    const handleLogout = () => {
        deleteCookie();
    };

    //orders variation
    const handleOrderItemVariation = (item) => {
        setNewOrder({
            ...newOrder,
            variation: item,
        });
    };

    //property
    const handleOrderItemProperty = (propertyItem) => {
        let newTemp = [];
        if (newOrder.properties !== null && newOrder.properties.length > 0) {
            let checkExist = newOrder.properties.find((exist) => {
                return exist.id === propertyItem.id;
            });
            if (checkExist === undefined) {
                newOrder.properties.map((oldItem) => {
                    newTemp.push(oldItem);
                });
                propertyItem.quantity = 1;
                newTemp.push(propertyItem);
            } else {
                newOrder.properties.map((oldItem) => {
                    if (oldItem.id !== propertyItem.id) {
                        newTemp.push(oldItem);
                    }
                });
            }
            setNewOrder({
                ...newOrder,
                properties: newTemp,
            });
        } else {
            propertyItem.quantity = 1;
            setNewOrder({
                ...newOrder,
                properties: [propertyItem],
            });
        }
    };

    //multiple qty
    const checkedProperty = (eachItem) => {
        if (newOrder.properties !== null) {
            let findChecked = newOrder.properties.find((checkIt) => {
                return checkIt.id === eachItem.id;
            });
            if (findChecked === undefined) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    //property checked
    const checkCheckedPropertyQuantity = (propertyItem) => {
        if (newOrder.properties !== null) {
            let theItem = newOrder.properties.find((findThis) => {
                return findThis.id === propertyItem.id;
            });
            if (theItem !== undefined) {
                return theItem.quantity;
            } else {
                return 1;
            }
        } else {
            return 1;
        }
    };

    //set propertyqty
    const handlePropertyQty = (propertyItem, action) => {
        let newTemp = [];
        if (newOrder.properties !== null && newOrder.properties.length > 0) {
            newOrder.properties.map((pushThis) => {
                if (pushThis.id === propertyItem.id) {
                    if (action === "+") {
                        pushThis.quantity = pushThis.quantity + 1;
                        newTemp.push(pushThis);
                    } else {
                        if (pushThis.quantity > 1) {
                            pushThis.quantity = pushThis.quantity - 1;
                        }
                        newTemp.push(pushThis);
                    }
                } else {
                    newTemp.push(pushThis);
                }
            });
            setNewOrder({
                ...newOrder,
                properties: newTemp,
            });
        }
    };

    //get already ordered qty
    const handleAlreadyOrderedQty = (id) => {
        let temp = 0;
        if (orderDetails.items.length > 0) {
            orderDetails.items.map((item) => {
                if (parseInt(item.item.id) === id) {
                    temp += parseInt(item.quantity);
                }
            });
        }
        return temp;
    };

    //add to cart
    const handleOrder = () => {
        // check if manage stock is enable
        if (showManageStock) {
            if (
                handleGetStock(foodItems.selectedItem.id) >
                handleAlreadyOrderedQty(foodItems.selectedItem.id)
            ) {
                let tempPrice = 0;
                //if no variation
                if (newOrder.variation !== null) {
                    tempPrice = parseFloat(newOrder.variation.food_with_variation_price);
                } else {
                    tempPrice = parseFloat(foodItems.selectedItem.price);
                }

                if (newOrder.properties !== null && newOrder.properties.length > 0) {
                    let tempPropertyPrice = 0;
                    newOrder.properties.map((propertyItem, propertyItemIndex) => {
                        tempPropertyPrice =
                            tempPropertyPrice +
                            parseFloat(propertyItem.extra_price) * propertyItem.quantity;
                    });
                    tempPrice = tempPrice + tempPropertyPrice;
                }

                let tempOrderItem = {
                    item: foodItems.selectedItem,
                    quantity: newOrder.quantity,
                    variation: newOrder.variation,
                    properties: newOrder.properties,
                    subTotal: tempPrice,
                };
                setOrderDetails({
                    ...orderDetails,
                    items: [...orderDetails.items, tempOrderItem],
                });
                setShowVariation(false);
                setShowCheckout(false);
                setShowCart(true);
            } else {
                toast.error(`${_t(t("Stock Out"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            }
        } else {
            let tempPrice = 0;
            //if no variation
            if (newOrder.variation !== null) {
                tempPrice = parseFloat(newOrder.variation.food_with_variation_price);
            } else {
                tempPrice = parseFloat(foodItems.selectedItem.price);
            }

            if (newOrder.properties !== null && newOrder.properties.length > 0) {
                let tempPropertyPrice = 0;
                newOrder.properties.map((propertyItem, propertyItemIndex) => {
                    tempPropertyPrice =
                        tempPropertyPrice +
                        parseFloat(propertyItem.extra_price) * propertyItem.quantity;
                });
                tempPrice = tempPrice + tempPropertyPrice;
            }

            let tempOrderItem = {
                item: foodItems.selectedItem,
                quantity: newOrder.quantity,
                variation: newOrder.variation,
                properties: newOrder.properties,
                subTotal: tempPrice,
            };
            setOrderDetails({
                ...orderDetails,
                items: [...orderDetails.items, tempOrderItem],
            });
            setShowVariation(false);
            setShowCheckout(false);
            setShowCart(true);
        }

    };

    //stock
    const handleGetStock = (id) => {
        if (orderDetails.branch === null) {
            return 0;
        }
        let stock = foodStockWeb.find((item) => {
            return (
                parseInt(item.food_id) === parseInt(id) &&
                parseInt(item.branch_id) === parseInt(orderDetails.branch)
            );
        });
        if (stock === undefined || stock.qty < 0) {
            return 0;
        }
        return stock.qty;
    };

    //order quantity
    const handleQty = (cartItemIndex, action) => {
        let oldItems = [];
        orderDetails.items.map((orderItem, orderItemIndex) => {
            if (orderItemIndex !== cartItemIndex) {
                oldItems.push(orderItem);
            } else {
                if (action === "+") {
                    let temp = orderItem;

                    // check manage stock enable
                    if (showManageStock) {
                        let stock = handleGetStock(temp.item.id);
                        if (stock > handleAlreadyOrderedQty(temp.item.id)) {
                            temp.quantity = temp.quantity + 1;
                        } else {
                            toast.error(`${_t(t("Reached Stock Limit"))}`, {
                                position: "bottom-center",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                className: "text-center toast-notification",
                            });
                        }
                    } else {
                        temp.quantity = temp.quantity + 1;
                    }

                    let tempPrice = 0;
                    //if no variation
                    if (newOrder.variation !== null) {
                        tempPrice = parseFloat(
                            newOrder.variation.food_with_variation_price
                        );
                    } else {
                        tempPrice = parseFloat(foodItems.selectedItem.price);
                    }

                    if (newOrder.properties !== null && newOrder.properties.length > 0) {
                        let tempPropertyPrice = 0;
                        newOrder.properties.map((propertyItem, propertyItemIndex) => {
                            tempPropertyPrice =
                                tempPropertyPrice +
                                parseFloat(propertyItem.extra_price) * propertyItem.quantity;
                        });
                        tempPrice = tempPrice + tempPropertyPrice;
                    }

                    temp.subTotal = tempPrice * temp.quantity;
                    oldItems.push(temp);
                } else {
                    let temp = orderItem;
                    if (temp.quantity > 1) {
                        temp.quantity = temp.quantity - 1;
                        let tempPrice = 0;
                        //if no variation
                        if (newOrder.variation !== null) {
                            tempPrice = parseFloat(
                                newOrder.variation.food_with_variation_price
                            );
                        } else {
                            tempPrice = parseFloat(foodItems.selectedItem.price);
                        }

                        if (
                            newOrder.properties !== null &&
                            newOrder.properties.length > 0
                        ) {
                            let tempPropertyPrice = 0;
                            newOrder.properties.map((propertyItem, propertyItemIndex) => {
                                tempPropertyPrice =
                                    tempPropertyPrice +
                                    parseFloat(propertyItem.extra_price) * propertyItem.quantity;
                            });
                            tempPrice = tempPrice + tempPropertyPrice;
                        }

                        temp.subTotal = tempPrice * temp.quantity;
                        oldItems.push(temp);
                    }
                }
            }
        });
        setOrderDetails({ ...orderDetails, items: oldItems });
    };

    //calculate total
    const getTotal = () => {
        let total = 0;
        if (orderDetails.items.length > 0) {
            orderDetails.items.map((temp) => {
                total += temp.subTotal;
            });
        }
        return total;
    };

    //calculate vat
    const getVat = () => {
        let vat = 0;
        let rate = parseFloat(getSystemSettings(generalSettings, "type_vat"));
        vat = (getTotal() * rate) / 100;
        return vat;
    };

    //handle changes
    const handleChange = (e) => {
        e.preventDefault();
        setOrderDetails({
            ...orderDetails,
            [e.target.name]: e.target.value,
        });
    };
    // stripe payment states
    const [stripeDetails, setstripeDetails] = useState({
        stripe_card_name: null,
        stripe_card_number: null,
        stripe_card_cvc: null,
        stripe_card_exp_month: null,
        stripe_card_exp_year: null,
        stripe_zip_code: null,
    });
    //submit order cod
    const handleOrderSubmit = (e) => {
        setOrderDetails({
            ...orderDetails,
            uploading: true,
        });
        e.preventDefault();
        let url = BASE_URL + "/website/order";
        let formData = orderDetails;
        formData.subTotal = getTotal();
        formData.vat = getVat();
        formData.delivery_charge = branchDeliveryCharge;
        return axios
            .post(url, orderDetails, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                if (res.data !== "ended") {
                    setShowCheckout(false);
                    setFoodStockWeb(res.data);
                    setOrderDetails({
                        items: [],
                        branch: null,
                        workPeriod: null,
                        workPeriodStatus: false,
                        address: authUserInfo.details && authUserInfo.details.address,
                        name: authUserInfo.details && authUserInfo.details.name,
                        phn_no: authUserInfo.details && authUserInfo.details.phn_no,
                        note: null,
                        payment_type: "COD",
                        uploading: false,
                    });
                    toast.success(`${_t(t("Your order has been placed"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                } else {
                    toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                    setOrderDetails({
                        ...orderDetails,
                        uploading: false,
                    });
                }
            })
            .catch((err) => {
                setOrderDetails({
                    ...orderDetails,
                    uploading: false,
                });
                toast.error(`${_t(t("Please try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            });
    };

    // handle stripe on change
    const onchangehandleStripePayment = (e) => {
        setstripeDetails({ ...stripeDetails, [e.target.name]: e.target.value })
    };

    // stripe handle submit
    const onsubmithandleStripePayment = (e) => {
        e.preventDefault();
        document.getElementById('stripePaynow').innerHTML = "Please wait...";
        let url = BASE_URL + "/settings/get-stripe-data";

        let formdata = stripeDetails;
        formdata.subTotal = getTotal();
        formdata.vat = getVat();
        formdata.delivery_charge = branchDeliveryCharge;
        return axios
            .post(url, formdata, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                // make reuseble payment component
                if (res.data.status == 422) {
                    document.getElementById('stripePaynow').innerHTML = "Pay Now";
                    toast.error(`${_t(t("Please enter valid info"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                } else {
                    const payment_id = res.data.card.id;
                    const payment_type = "STRIPE";

                    // send data to serve start
                    setOrderDetails({
                        ...orderDetails,
                        uploading: true,
                    });
                    e.preventDefault();
                    let url = BASE_URL + "/website/order";
                    let formData = orderDetails;
                    formData.subTotal = getTotal();
                    formData.vat = getVat();
                    formData.payment_type = payment_type;
                    formData.payment_id = payment_id;
                    formData.delivery_charge = branchDeliveryCharge;
                    return axios
                        .post(url, orderDetails, {
                            headers: { Authorization: `Bearer ${getCookie()}` },
                        })
                        .then((res) => {
                            if (res.data !== "ended") {
                                setShowCheckout(false);
                                setFoodStockWeb(res.data);
                                setOrderDetails({
                                    items: [],
                                    branch: null,
                                    workPeriod: null,
                                    workPeriodStatus: false,
                                    address: authUserInfo.details && authUserInfo.details.address,
                                    name: authUserInfo.details && authUserInfo.details.name,
                                    phn_no: authUserInfo.details && authUserInfo.details.phn_no,
                                    note: null,
                                    payment_type: "COD",
                                    uploading: false,
                                });
                                toast.success(`${_t(t("Your order has been placed"))}`, {
                                    position: "bottom-center",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    className: "text-center toast-notification",
                                });
                            } else {
                                toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
                                    position: "bottom-center",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    className: "text-center toast-notification",
                                });
                                setOrderDetails({
                                    ...orderDetails,
                                    uploading: false,
                                });
                            }
                            document.getElementById('stripePaynow').innerHTML = "Pay Now";
                        })
                        .catch((err) => {
                            setOrderDetails({
                                ...orderDetails,
                                uploading: false,
                            });
                            toast.error(`${_t(t("Please try again"))}`, {
                                position: "bottom-center",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                className: "text-center toast-notification",
                            });
                            document.getElementById('stripePaynow').innerHTML = "Pay Now";
                        });
                    // send data to serve end
                }

            })
            .catch((err) => {
                console.log(err);
            });
    };

    //set default language on site load
    const handleOnLoadDefaultLang = () => {
        let localLang = localStorage.i18nextLng;
        if (localLang) {
            if (localLang === undefined || localLang.includes("en-")) {
                navLanguageList &&
                    navLanguageList.map((item) => {
                        if (item.is_default === true) {
                            i18n.changeLanguage(item.code);
                            setDefaultLang(item);
                        }
                        return true;
                    });
            } else {
                const temp =
                    navLanguageList &&
                    navLanguageList.find((item) => {
                        return item.code === localLang;
                    });
                setDefaultLang(temp);
                i18n.changeLanguage(localLang);
            }
        }
    };

    //change language to selected
    const handleDefaultLang = (e) => {
        let lang =
            navLanguageList &&
            navLanguageList.find((theItem) => {
                return theItem.id === parseInt(e.target.value);
            });
        i18n.changeLanguage(lang.code);
        setDefaultLang(lang);
        toast.success(`${_t(t("Language has been switched!"))}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
    };

    //set default currency on site load
    const handleOnLoadDefaultCurrency = () => {
        let localCurrency = JSON.parse(localStorage.getItem("currency"));
        if (localCurrency === null) {
            navCurrencyList &&
                navCurrencyList.map((item) => {
                    if (item.is_default === true) {
                        setDefaultCurrency(item);
                        localStorage.setItem("currency", JSON.stringify(item));
                    }
                    return true;
                });
        } else {
            const temp =
                navCurrencyList &&
                navCurrencyList.find((item) => {
                    return item.code === localCurrency.code;
                });
            setDefaultCurrency(temp);
        }
    };

    //change currency to selected
    const handleDefaultCurrency = (e) => {
        let item =
            navCurrencyList &&
            navCurrencyList.find((theItem) => {
                return theItem.id === parseInt(e.target.value);
            });
        localStorage.setItem("currency", JSON.stringify(item));
        setDefaultCurrency(item);
        toast.success(`${_t(t("Currency has been changed!"))}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
    };

    //get selected branch
    const getSelectedBranch = (id) => {
        if (orderDetails && orderDetails.branch !== null) {
            if (id === orderDetails.branch.id) {
                return true;
            }
        }
        return false;
    };

    //paypal integration
    const initialOptions = {
        // "client-id": { paypal_client_id },
        "client-id": "AWOafqislzl8zx6-w5BwIOu9p-7DXKNt3Ly4hGzXYNRYBKJkY_yrUcAYSc5RP6YFz_ckikuYoDoBs9NK",
        currency: "USD",
        intent: "capture",
    };

    return (
        <>
            <Helmet>
                <title>
                    {generalSettings && getSystemSettings(generalSettings, "siteName")}
                </title>
                <link rel="stylesheet" href="/website/css/animate.css" />
                <link rel="stylesheet" href="/website/css/meanmenu.min.css" />
                <link rel="stylesheet" href="/website/css/bootstrap.min.css" />
                <link rel="stylesheet" href="/website/css/font-awsome-all.min.css" />
                <link rel="stylesheet" href="/website/css/magnific-popup.css" />
                <link rel="stylesheet" href="/website/css/slick.css" />
                <link rel="stylesheet" href="/website/css/jquery-ui.css" />
                <link rel="stylesheet" href="/website/css/style.css" />

                <script src="/website/js/vendor/jquery-2.2.4.min.js"></script>
                <script src="/website/js/vendor/bootstrap.min.js"></script>
                <script src="/website/js/vendor/jquery.meanmenu.min.js"></script>
                <script src="/website/js/vendor/jquery.magnific-popup.min.js"></script>
                <script src="/website/js/vendor/slick.min.js"></script>
                <script src="/website/js/vendor/counterup.min.js"></script>
                <script src="/website/js/vendor/countdown.js"></script>
                <script src="/website/js/vendor/waypoints.min.js"></script>
                <script src="/website/js/vendor/jquery-ui.js"></script>
                <script src="/website/js/vendor/isotope.pkgd.min.js"></script>
                <script src="/website/js/vendor/easing.min.js"></script>
                <script src="/website/js/vendor/wow.min.js"></script>
                <script src="/website/js/simplebar.js"></script>
                <script src="/website/js/main.js"></script>
            </Helmet>

            {/* <!-- Preloader Starts --> */}
            <div className={`preloader02 ${!loading && "d-none"}`} id="preloader02">
                <div className="preloader-inner">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
            </div>

            {/* template content section */}
            <div className={loading ? "d-none" : ""}>
                <Header/>
                
                <div className="banner-area breadcrumb-area padding-top-120 padding-bottom-90" style={{ backgroundImage:"url('website/images/img/catering/4.JPG')",height:"500px",backgroundRepeat: "no-repeat",backgroundPosition: 'center center',backgroundAttachment: 'scroll',backgroundSize: 'cover'}} >
                    <div className="bread-shapes">
                        {/*<span className="b-shape-1 item-bounce"><img src="website/images/img/5.png" alt=""/></span>*/}
                        {/*<span className="b-shape-2"><img src="website/images/img/6.png" alt=""/></span>*/}
                        {/*<span className="b-shape-3"><img src="website/images/img/7.png" alt=""/></span>*/}
                        {/*<span className="b-shape-4"><img src="website/images/img/9.png" alt=""/></span>*/}
                        {/*<span className="b-shape-5"><img src="website/images/shapes/18.png" alt=""/></span>*/}
                        {/*<span className="b-shape-6 item-animateOne"><img src="website/images/img/7.png" alt=""/></span>*/}
                    </div>
                    <div className="container padding-top-120">
                        <div className="row justify-content-center">
                            {/*<nav aria-label="breadcrumb">*/}
                            {/*    <h2 className="page-title">Catering Menu</h2>*/}
                            {/*    <ol className="breadcrumb text-center">*/}
                            {/*        <li className="breadcrumb-item"><a href="index.html">Home</a></li>*/}
                            {/*        <li className="breadcrumb-item active" aria-current="page">Catering Menu</li>*/}
                            {/*    </ol>*/}
                            {/*</nav>*/}
                        </div>
                    </div>
                </div>

                <div className="foods-counter menus-area">
                    <div className="container">
                        <div className="row foods-wrapper menus-wrapper">
                            <div className="col-lg-6 col-md-6">
                                <div className="single-food single-menus">
                                    <img src="website/images/menu-item/menu2.png" alt=""/>
                                        <h6>We Do All Middle Eastern Food</h6>
                                    <p>Green Corner Restaurant offers professional catered Mediterranean cuisine for all occasions.</p>

                                    <p>We are working on establishing a menu unique for our catering services. In the meantime, please click HERE for our full menu, and contact us to tell us about your special event.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="single-food single-menus">
                                    <img src="website/images/menu-item/menu1.png" alt=""/>
                                        <h6>Contact Us To Order</h6>
                                    <p>Email Us : <a href="mailto:info@greencorneraz.com">info@greencorneraz.com</a></p>
                                    <p>Chandler : <a href="#">(480) 553-7011</a></p>
                                    <p>Mesa : <a href="#">(480) 835-2313</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="menu-area  pizza-area burger-area chicken-area padding-top-40">
                    <div className="menu-i-shapes">
                        <span className="bleft"><img src="website/images/shapes/38.png" alt=""/></span>
                    </div>
                    <div className="container">
                        <div className="common-title-area text-center padding-40">
                            <h3>food items</h3>
                            <h2>Catering <span>menu</span></h2>
                        </div>

                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="chicken-1" role="tabpanel"
                                 aria-labelledby="chicken-1">
                                <div className="menu-items-wrapper pizza-items-wrapper  margin-top-50">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/13.jpg" style={{height:"200px"}} alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half Tray of Hummus</a></h6>
                                                            <p>(15-20 people) 1 bag pita</p>
                                                            <span>price :$45.00</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/13.jpg" style={{height:"200px"}} alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Full Tray of Hummus</a></h6>
                                                            <p>(40 people) 2 bag pita</p>
                                                            <span>price :$90.00</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/14.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half try of Garden Hummus</a></h6>
                                                            <p>(15-20 people) 1 bag pita</p>
                                                            <span>price :$50.00</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/14.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Full try of Garden Hummus</a></h6>
                                                            <p>(40people) 2 bag pita</p>
                                                            <span>price :$95.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/15.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half tray of Baba Ghannoug</a></h6>
                                                            <p>(15-20 people) 1 bag pita</p>
                                                            <span>price :$55.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/15.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Full tray of Baba Ghannoug</a></h6>
                                                            <p>(40 people) 2 bag pita</p>
                                                            <span>price :$100.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/16.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half tray of Rice</a></h6>
                                                            <p>(10 people)</p>
                                                            <span>price :$30.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/16.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Full tray of Rice</a></h6>
                                                            <p>(20-25 people)</p>
                                                            <span>price :$50.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/17.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half tray of Greek salad</a></h6>
                                                            <p>(10-15 people)</p>
                                                            <span>price :$35.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/17.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Full tray of Greek salad</a></h6>
                                                            <p>(20-25 people)</p>
                                                            <span>price :$65.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/18.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half tray of Tabouleh</a></h6>
                                                            <p>(10-15 people)</p>
                                                            <span>price :$45.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/18.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Full tray of Tabouleh</a></h6>
                                                            <p>(20-25 people)</p>
                                                            <span>price :$80.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/9.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half tray of Fattoush</a></h6>
                                                            <p>(10-15 people)</p>
                                                            <span>price :$35.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/9.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Full tray of Fattoush</a></h6>
                                                            <p>(20-25 people)</p>
                                                            <span>price :$65.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/2.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half tray of Chicken Shawarma</a></h6>
                                                            <p>(10 people)</p>
                                                            <span>price :$60.00</span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/2.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Full tray of Chicken Shawarma</a></h6>
                                                            <p>(25 people)</p>
                                                            <span>price :$120.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/1.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half tray of Beef Shawarma</a></h6>
                                                            <p>(10 people)</p>
                                                            <span>price :$70.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/1.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half tray of Beef Shawarma</a></h6>
                                                            <p>(25 people)</p>
                                                            <span>price :$140.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/19.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half tray of Gyro</a></h6>
                                                            <p>(10 people)</p>
                                                            <span>price :$60.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/19.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half tray of Gyro</a></h6>
                                                            <p>(25 people)</p>
                                                            <span>price :$120.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/11.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half tray of Mix Shawarma</a></h6>
                                                            <p>(10 people)</p>
                                                            <span>price :$65.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/11.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half tray of Mix Shawarma</a></h6>
                                                            <p>(25 people)</p>
                                                            <span>price :$130.00</span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/5.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Falafel</a></h6>
                                                            <span>price :$0.65 ea</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/20.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Stuffed Grape Leaves</a></h6>
                                                            <span>price :$0.75 ea</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/21.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Half tray of Greek Fries</a></h6>
                                                            <span>price :$30.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/21.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Full tray of Greek Fries</a></h6>
                                                            <span>price :$60.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/7.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Full tray of Frekeh with Chicken</a></h6>
                                                            <span>price :$140.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/7.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Full tray of Rice with Chicken</a></h6>
                                                            <span>price :$135.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/7.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Whole Lamb with Rice</a></h6>
                                                            <span>price :$390.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/22.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Baklava Whole Tray</a></h6>
                                                            <span>price :$45.00</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/23.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Lamb Shank</a></h6>
                                                            <span>price :$10.00 ea</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/24.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Chicken Kabob</a></h6>
                                                            <span>price :$4.49 ea Skewer</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/24.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Lamb Kafta Kabob</a></h6>
                                                            <span>price :$4.49 ea Skewer</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/25.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Beef Shish (Tekkah)</a></h6>
                                                            <span>price :$4.49 ea Skewer</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/1.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Sham Beef Sandwichs in tray</a></h6>
                                                            <p>(Minimum 5 sandwichs )</p>
                                                            <span>price :$10.49 ea</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/2.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Sham Chicken Sandwichs in tray</a></h6>
                                                            <p>(Minimum 5 sandwichs )</p>
                                                            <span>price :$10.49 ea</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/5.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Sham Falafel Sandwichs in tray</a></h6>
                                                            <p>(Minimum 5 sandwichs )</p>
                                                            <span>price :$10.49 ea</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/5.JPG" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Sham Falafel Sandwichs in tray</a></h6>
                                                            <p>(Minimum 5 sandwichs )</p>
                                                            <span>price :$10.49 ea</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/img/shawrma.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>16oz of Tzatziki</a></h6>
                                                            <span>price :$6.99</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/26.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>16oz of Garlic</a></h6>
                                                            <span>price :$6.99</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/26.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>16oz of Tahini</a></h6>
                                                            <span>price :$6.99</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/27.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Pita bread bag</a></h6>
                                                            <span>price :$3.50 ea</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4 col-md-4">
                                                    <div className="single-menu-item d-flex justify-content-between">
                                                        <div className="menu-img">
                                                            <img src="website/images/img/catering/29.jpg" alt=""/>
                                                        </div>
                                                        <div className="menu-content">
                                                            <h6><a>Greek Pita bag</a></h6>
                                                            <span>price :$6.00 ea</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <Footer/>
            </div>
            {/* template content end here */}
        </>
    );
};

export default Catering;

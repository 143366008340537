import React from 'react';
import Slider from "react-slick";

const Testimonial = () => {

    const settings = {
        dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: false,
        className: "testimonial-active",
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <section class="testimonial padding-bottom-50 padding-top-50">
                <div class="container">
                    <div class="testi-shapes">
                        <span class="ts-1"><img src="/website/images/img/5.png" alt="" /></span>
                        <span class="ts-2"><img src="/website/images/img/32.png" alt="" /></span>
                        <span class="ts-3 item-animateTwo"><img src="/website/images/shapes/7.png" alt="" /></span>
                        <span class="ts-4"><img src="/website/images/shapes/26.png" alt="" /></span>
                    </div>
                    <div class="common-title-area text-center padding-bottom-50 wow fadeInUp">
                        <h3>testimonial</h3>
                        <h2>client <span>feedback</span></h2>
                    </div>
                    <Slider {...settings}>
                        <div class="single-testimonial">
                            <div class="testi-top">
                                <div class="tin-shapes">
                                    <span class="tsin-1"><img src="/website/images/shapes/33.png" alt="" /></span>

                                </div>
                                <div class="testi-img">
                                    <img src="/website/images/img/review/Monique.jpg" style={{width:"100px"}} alt="" />
                                </div>
                                <div class="testi-meta">
                                    <h6>Monique</h6>
                                    {/*<p>CEO A4Tech Ltd.</p>*/}
                                    <div class="testi-rating">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                </div>
                            </div>
                            <p>This restaurant is probably my fav! I go there at least twice a week. If you are in the area, definitely
                                go in and try the kabobs.</p>
                        </div>
                        <div class="single-testimonial">
                            <div class="testi-top">
                                <div class="tin-shapes">
                                    <span class="tsin-1"><img src="/website/images/shapes/33.png" alt="" /></span>

                                </div>
                                <div class="testi-img">
                                    <img src="/website/images/img/review/Cali.jpg" style={{width:"100px"}} alt="" />
                                </div>
                                <div class="testi-meta">
                                    <h6>Cali</h6>
                                    {/*<p>CEO SoftTechit Ltd.</p>*/}
                                    <div class="testi-rating">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                </div>
                            </div>
                            <p>The gyros was the best I ever had hands down. The Greek dressing was amazing, the
                                lasted has lots of yumi veggies on it.</p>
                        </div>
                        <div class="single-testimonial">
                            <div class="testi-top">
                                <div class="tin-shapes">
                                    <span class="tsin-1"><img src="/website/images/shapes/33.png" alt="" /></span>

                                </div>
                                <div class="testi-img">
                                    <img src="/website/images/img/review/Ashley.jpg" style={{width:"100px"}} alt="" />
                                </div>
                                <div class="testi-meta">
                                    <h6>Ashley</h6>
                                    {/*<p>CEO A4Tech Ltd.</p>*/}
                                    <div class="testi-rating">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                </div>
                            </div>
                            <p>This place is delicious with good portion sizes and prices. You can't go wrong with
                                the gyro or chicken and the salads are very fresh.</p>
                        </div>
                        <div class="single-testimonial">
                            <div class="testi-top">
                                <div class="tin-shapes">
                                    <span class="tsin-1"><img src="/website/images/shapes/33.png" alt="" /></span>

                                </div>
                                <div class="testi-img">
                                    <img src="/website/images/img/review/donna.jpg" style={{width:"100px"}} alt="" />
                                </div>
                                <div class="testi-meta">
                                    <h6>donna</h6>
                                    {/*<p>CEO SoftTechit Ltd.</p>*/}
                                    <div class="testi-rating">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                </div>
                            </div>
                            <p>My friend recommended this place. It was our first time here. The prices are affordable and the portions
                                are large! I took a photo compared to my hand.</p>
                        </div>
                        <div class="single-testimonial">
                            <div class="testi-top">
                                <div class="tin-shapes">
                                    <span class="tsin-1"><img src="/website/images/shapes/33.png" alt="" /></span>

                                </div>
                                <div class="testi-img">
                                    <img src="/website/images/img/review/matt.jpg" style={{width:"100px"}} alt="" />
                                </div>
                                <div class="testi-meta">
                                    <h6>matt</h6>
                                    {/*<p>CEO A4Tech Ltd.</p>*/}
                                    <div class="testi-rating">
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                        <span><i class="fas fa-star"></i></span>
                                    </div>
                                </div>
                            </div>
                            <p>Great food for a great price. The gyro sandwich was delicious, although that was the only thing I've
                                had. If it ain't broke, I'm not going to fix it.</p>
                        </div>
                    </Slider>

                </div>
            </section>
        </>
    );
}

export default Testimonial;

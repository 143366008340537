import React from 'react';
import Slider from "react-slick";

const Blog = () => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        className: "blog-slider-active",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <section class="blog-area padding-bottom-50">
                <div class="blog-shapes">
                    <span class="bs-1"><img src="/website/images/img/32.png" alt="" /></span>
                </div>
                <div class="container">
                    <div class="common-title-area text-center padding-bottom-60 wow fadeInUp">
                        <h3>Our Blog</h3>
                        <h2>blog & <span>
                            news
                        </span> </h2>
                    </div>
                    <Slider {...settings}>
                        <div class="single-blog px-4">
                            <div class="blog-img">
                                <a href="blog-single.html"> <img src="/website/images/blog/blog-300x225.jpg" alt="" /></a>
                                <div class="b-badge">
                                    <span class="date"><a href="#">23</a></span><br></br>
                                    <span class="month"><a href="#">April</a></span>
                                </div>
                            </div>
                            <div class="blog-meta d-flex justify-content-between">
                                {/*<span><a href="#"><i class="fas fa-tags"></i> price</a></span>*/}
                                {/*<span><a href="#"><i class="fas fa-user-circle"></i> </a></span>*/}
                            </div>
                            <h4><a href="blog-single">The best sandwich in every state</a></h4>
                        </div>
                        <div className="single-blog px-4">
                            <div className="blog-img">
                                <a href="blog-single.html"> <img src="/website/images/img/img/blog2.jpg"
                                                                 alt=""/></a>
                                <div className="b-badge">
                                    <span className="date"><a href="#">14</a></span><br></br>
                                    <span className="month"><a href="#">May</a></span>
                                </div>
                            </div>
                            <div className="blog-meta d-flex justify-content-between">
                                {/*<span><a href="#"><i class="fas fa-tags"></i> price</a></span>*/}
                                {/*<span><a href="#"><i class="fas fa-user-circle"></i> </a></span>*/}
                            </div>
                            <h4><a href="blog-single">The best plate in every state</a></h4>
                        </div>

                    </Slider>
                </div>
            </section>
        </>
    );
}

export default Blog;

import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

//importing context consumer here
import { UserContext } from "../../contexts/User";
import { FoodContext } from "../../contexts/Food";
import { RestaurantContext } from "../../contexts/Restaurant";
import { SettingsContext } from "../../contexts/Settings";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../src/BaseUrl";

//functions
import {
    _t,
    modalLoading,
    restaurantMenuLink,
    getSystemSettings,
    getCookie,
    deleteCookie,
    currencySymbolLeft,
    formatPrice,
    currencySymbolRight,
} from "../../functions/Functions";

//3rd party packages
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//jQuery initialization
import $ from "jquery";

// template file
import Testimonial from "./Testimonial";
import Gallery from "./Gallery";
import Blog from "./Blog";
import DeliverySection from "./DeliverySection";
import Countdown from "./Countdown";
import Banner from "./Banner";
import About from "./About";
import Header from "./Header";
import Footer from "./Footer";


const TemplateThree = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();

    //getting context values here
    let {
        navLanguageList,
        navCurrencyList,
        generalSettings,
        showManageStock,
        paypal_client_id
    } =
        useContext(SettingsContext);
    //auth user
    const { authUserInfo } = useContext(UserContext);
    //restaurant
    let { branchForSearch } = useContext(RestaurantContext);
    //food
    let {
        getFoodWeb,
        foodListWeb,
        foodGroupWeb,
        propertyGroupWeb,
        workPeriodWeb,
        foodStockWeb,
        setFoodStockWeb,
    } = useContext(FoodContext);

    //use state
    const [loading, setLoading] = useState(true);
    const [showCart, setShowCart] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);
    const [showVariation, setShowVariation] = useState(false);

    // stripe
    const [showStripe, setshowStripe] = useState('');

    const [deliverymenShow, setdeliverymenShow] = useState(false);
    const [checkLoginUser, setCheckLoginUser] = useState(0);
    //
    const [defaultLang, setDefaultLang] = useState(null);
    const [defaultCurrency, setDefaultCurrency] = useState(null);

    // branch delivery charge
    const [branchDeliveryCharge, setBranchDeliveryCharge] = useState(0);

    //food and group
    const [foodItems, setFoodItems] = useState({
        list: null,
        group: null,
        selectedItem: null,
        variations: null,
        properties: null,
    });

    //new order
    const [newOrder, setNewOrder] = useState({
        variation: null,
        quantity: 1,
        properties: null,
    });

    //order details
    const [orderDetails, setOrderDetails] = useState({
        items: [],
        branch: null,
        workPeriod: null,
        workPeriodStatus: false,
        address: null,//null
        name: null,
        phn_no: null,
        note: null,
        payment_type: "COD",
        uploading: false,
    });

    // check auth user 
    const checkLoginfunc = () => {
        getCookie() !== undefined ? setCheckLoginUser(0) : setCheckLoginUser(1);
    }

    // show stripe 
    const showstripebtn = (e) => {
        setLoading(true);
        const url = BASE_URL + `/settings/show-stripe-btn`;
        return axios
            .get(url)
            .then((res) => {
                setshowStripe(res.data);
                setLoading(false);
            })
    }

    //useeffect == componentDidMount()
    useEffect(() => {
        handleJquery();
        getFoodWeb();
        deliveryMenu();
        handleOnLoadDefaultLang();
        handleOnLoadDefaultCurrency();
        checkLoginfunc();
        showstripebtn();
        setOrderDetails({
            ...orderDetails,
            address: authUserInfo.details && authUserInfo.details.address,
            name: authUserInfo.details && authUserInfo.details.name,
            phn_no: authUserInfo.details && authUserInfo.details.phn_no,
        });
        if (foodGroupWeb && foodListWeb) {
            let temp = foodListWeb.filter((foodItem, foodItemIndex) => {
                return parseInt(foodItem.food_group_id) === foodGroupWeb[0].id;
            });
            setFoodItems({ ...foodItems, list: temp, group: foodGroupWeb[0] });
        }
        setTimeout(() => {
            setLoading(false);
        }, 2500);

    }, [authUserInfo, navCurrencyList,]);


    // deliveryman menu update
    const deliveryMenu = () => {
        const url = BASE_URL + `/settings/deliverymen-menu-info`;
        axios.get(url, {
            headers: { Authorization: `Bearer ${getCookie()}` },
        }).then((res) => {
            if (res.data.length == 0 || res.data[0].value == 1) {
                setdeliverymenShow(true);
            } else {
                setdeliverymenShow(false);
            }
        });

    }

    //handle jQuery
    const handleJquery = () => {
        $(window).on("scroll", function () {
            var toTopVisible = $("html").scrollTop();
            if (toTopVisible > 500) {
                $(".scrollup").fadeIn();
            } else {
                $(".scrollup").fadeOut();
            }
        });

        // MouseHover Animation home 1
        var hoverLayer = $(".banner-area");
        var heroImgOne = $(".p-shape-1");
        var heroImgTwo = $(".p-shape-2");
        var heroImgThree = $(".p-shape-3");
        var heroImgFour = $(".p-shape-4");
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 100;
            var valueY = (e.pageY * -1) / 120;
            heroImgOne.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 60;
            var valueY = (e.pageY * -1) / 80;
            heroImgTwo.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 40;
            var valueY = (e.pageY * -1) / 60;
            heroImgThree.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 80;
            var valueY = (e.pageY * -1) / 100;
            heroImgFour.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });

        // MouseHover Animation home 2
        var hoverLayer2 = $(".burger-promo-area");
        var heroImgfive = $(".bs1");
        var heroImgsix = $(".bs2");
        var heroImgseven = $(".bs5");
        var heroImgeight = $(".bs6");
        hoverLayer2.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 100;
            var valueY = (e.pageY * -1) / 120;
            heroImgfive.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer2.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 60;
            var valueY = (e.pageY * -1) / 80;
            heroImgsix.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer2.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 40;
            var valueY = (e.pageY * -1) / 60;
            heroImgseven.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer2.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 80;
            var valueY = (e.pageY * -1) / 100;
            heroImgeight.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });

        // MouseHover Animation home 3
        var hoverLayer3 = $(".snack-section");
        var heroImgnine = $(".ss1");
        var heroImgten = $(".ss2");
        var heroImgeleven = $(".ss3");
        var heroImgtweleve = $(".mss2");
        hoverLayer3.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 100;
            var valueY = (e.pageY * -1) / 120;
            heroImgtweleve.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer3.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 60;
            var valueY = (e.pageY * -1) / 80;
            heroImgnine.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer3.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 40;
            var valueY = (e.pageY * -1) / 60;
            heroImgten.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
        hoverLayer3.mousemove(function (e) {
            var valueX = (e.pageX * -1) / 80;
            var valueY = (e.pageY * -1) / 100;
            heroImgeleven.css({
                transform: "translate3d(" + valueX + "px," + valueY + "px, 0)",
            });
        });
    };

    //dynamic style
    const style = {
        logo: {
            backgroundImage:
                generalSettings &&
                `url(${getSystemSettings(generalSettings, "type_logo")})`,
        },
        currency: {
            backgroundColor:
                generalSettings && getSystemSettings(generalSettings, "type_clock"),
            color:
                generalSettings && getSystemSettings(generalSettings, "type_color"),
        },
    };

    //logout
    const handleLogout = () => {
        deleteCookie();
    };

    //orders variation
    const handleOrderItemVariation = (item) => {
        setNewOrder({
            ...newOrder,
            variation: item,
        });
    };

    //property
    const handleOrderItemProperty = (propertyItem) => {
        let newTemp = [];
        if (newOrder.properties !== null && newOrder.properties.length > 0) {
            let checkExist = newOrder.properties.find((exist) => {
                return exist.id === propertyItem.id;
            });
            if (checkExist === undefined) {
                newOrder.properties.map((oldItem) => {
                    newTemp.push(oldItem);
                });
                propertyItem.quantity = 1;
                newTemp.push(propertyItem);
            } else {
                newOrder.properties.map((oldItem) => {
                    if (oldItem.id !== propertyItem.id) {
                        newTemp.push(oldItem);
                    }
                });
            }
            setNewOrder({
                ...newOrder,
                properties: newTemp,
            });
        } else {
            propertyItem.quantity = 1;
            setNewOrder({
                ...newOrder,
                properties: [propertyItem],
            });
        }
    };

    //multiple qty
    const checkedProperty = (eachItem) => {
        if (newOrder.properties !== null) {
            let findChecked = newOrder.properties.find((checkIt) => {
                return checkIt.id === eachItem.id;
            });
            if (findChecked === undefined) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    };

    //property checked
    const checkCheckedPropertyQuantity = (propertyItem) => {
        if (newOrder.properties !== null) {
            let theItem = newOrder.properties.find((findThis) => {
                return findThis.id === propertyItem.id;
            });
            if (theItem !== undefined) {
                return theItem.quantity;
            } else {
                return 1;
            }
        } else {
            return 1;
        }
    };

    //set propertyqty
    const handlePropertyQty = (propertyItem, action) => {
        let newTemp = [];
        if (newOrder.properties !== null && newOrder.properties.length > 0) {
            newOrder.properties.map((pushThis) => {
                if (pushThis.id === propertyItem.id) {
                    if (action === "+") {
                        pushThis.quantity = pushThis.quantity + 1;
                        newTemp.push(pushThis);
                    } else {
                        if (pushThis.quantity > 1) {
                            pushThis.quantity = pushThis.quantity - 1;
                        }
                        newTemp.push(pushThis);
                    }
                } else {
                    newTemp.push(pushThis);
                }
            });
            setNewOrder({
                ...newOrder,
                properties: newTemp,
            });
        }
    };

    //get already ordered qty
    const handleAlreadyOrderedQty = (id) => {
        let temp = 0;
        if (orderDetails.items.length > 0) {
            orderDetails.items.map((item) => {
                if (parseInt(item.item.id) === id) {
                    temp += parseInt(item.quantity);
                }
            });
        }
        return temp;
    };

    //add to cart
    const handleOrder = () => {
        // check if manage stock is enable
        if (showManageStock) {
            if (
                handleGetStock(foodItems.selectedItem.id) >
                handleAlreadyOrderedQty(foodItems.selectedItem.id)
            ) {
                let tempPrice = 0;
                //if no variation
                if (newOrder.variation !== null) {
                    tempPrice = parseFloat(newOrder.variation.food_with_variation_price);
                } else {
                    tempPrice = parseFloat(foodItems.selectedItem.price);
                }

                if (newOrder.properties !== null && newOrder.properties.length > 0) {
                    let tempPropertyPrice = 0;
                    newOrder.properties.map((propertyItem, propertyItemIndex) => {
                        tempPropertyPrice =
                            tempPropertyPrice +
                            parseFloat(propertyItem.extra_price) * propertyItem.quantity;
                    });
                    tempPrice = tempPrice + tempPropertyPrice;
                }

                let tempOrderItem = {
                    item: foodItems.selectedItem,
                    quantity: newOrder.quantity,
                    variation: newOrder.variation,
                    properties: newOrder.properties,
                    subTotal: tempPrice,
                };
                setOrderDetails({
                    ...orderDetails,
                    items: [...orderDetails.items, tempOrderItem],
                });
                setShowVariation(false);
                setShowCheckout(false);
                setShowCart(true);
            } else {
                toast.error(`${_t(t("Stock Out"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            }
        } else {
            let tempPrice = 0;
            //if no variation
            if (newOrder.variation !== null) {
                tempPrice = parseFloat(newOrder.variation.food_with_variation_price);
            } else {
                tempPrice = parseFloat(foodItems.selectedItem.price);
            }

            if (newOrder.properties !== null && newOrder.properties.length > 0) {
                let tempPropertyPrice = 0;
                newOrder.properties.map((propertyItem, propertyItemIndex) => {
                    tempPropertyPrice =
                        tempPropertyPrice +
                        parseFloat(propertyItem.extra_price) * propertyItem.quantity;
                });
                tempPrice = tempPrice + tempPropertyPrice;
            }

            let tempOrderItem = {
                item: foodItems.selectedItem,
                quantity: newOrder.quantity,
                variation: newOrder.variation,
                properties: newOrder.properties,
                subTotal: tempPrice,
            };
            setOrderDetails({
                ...orderDetails,
                items: [...orderDetails.items, tempOrderItem],
            });
            setShowVariation(false);
            setShowCheckout(false);
            setShowCart(true);
        }

    };

    //stock
    const handleGetStock = (id) => {
        if (orderDetails.branch === null) {
            return 0;
        }
        let stock = foodStockWeb.find((item) => {
            return (
                parseInt(item.food_id) === parseInt(id) &&
                parseInt(item.branch_id) === parseInt(orderDetails.branch)
            );
        });
        if (stock === undefined || stock.qty < 0) {
            return 0;
        }
        return stock.qty;
    };

    //order quantity
    const handleQty = (cartItemIndex, action) => {
        let oldItems = [];
        orderDetails.items.map((orderItem, orderItemIndex) => {
            if (orderItemIndex !== cartItemIndex) {
                oldItems.push(orderItem);
            } else {
                if (action === "+") {
                    let temp = orderItem;

                    // check manage stock enable
                    if (showManageStock) {
                        let stock = handleGetStock(temp.item.id);
                        if (stock > handleAlreadyOrderedQty(temp.item.id)) {
                            temp.quantity = temp.quantity + 1;
                        } else {
                            toast.error(`${_t(t("Reached Stock Limit"))}`, {
                                position: "bottom-center",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                className: "text-center toast-notification",
                            });
                        }
                    } else {
                        temp.quantity = temp.quantity + 1;
                    }

                    let tempPrice = 0;
                    //if no variation
                    if (newOrder.variation !== null) {
                        tempPrice = parseFloat(
                            newOrder.variation.food_with_variation_price
                        );
                    } else {
                        tempPrice = parseFloat(foodItems.selectedItem.price);
                    }

                    if (newOrder.properties !== null && newOrder.properties.length > 0) {
                        let tempPropertyPrice = 0;
                        newOrder.properties.map((propertyItem, propertyItemIndex) => {
                            tempPropertyPrice =
                                tempPropertyPrice +
                                parseFloat(propertyItem.extra_price) * propertyItem.quantity;
                        });
                        tempPrice = tempPrice + tempPropertyPrice;
                    }

                    temp.subTotal = tempPrice * temp.quantity;
                    oldItems.push(temp);
                } else {
                    let temp = orderItem;
                    if (temp.quantity > 1) {
                        temp.quantity = temp.quantity - 1;
                        let tempPrice = 0;
                        //if no variation
                        if (newOrder.variation !== null) {
                            tempPrice = parseFloat(
                                newOrder.variation.food_with_variation_price
                            );
                        } else {
                            tempPrice = parseFloat(foodItems.selectedItem.price);
                        }

                        if (
                            newOrder.properties !== null &&
                            newOrder.properties.length > 0
                        ) {
                            let tempPropertyPrice = 0;
                            newOrder.properties.map((propertyItem, propertyItemIndex) => {
                                tempPropertyPrice =
                                    tempPropertyPrice +
                                    parseFloat(propertyItem.extra_price) * propertyItem.quantity;
                            });
                            tempPrice = tempPrice + tempPropertyPrice;
                        }

                        temp.subTotal = tempPrice * temp.quantity;
                        oldItems.push(temp);
                    }
                }
            }
        });
        setOrderDetails({ ...orderDetails, items: oldItems });
    };

    //calculate total
    const getTotal = () => {
        let total = 0;
        if (orderDetails.items.length > 0) {
            orderDetails.items.map((temp) => {
                total += temp.subTotal;
            });
        }
        return total;
    };

    //calculate vat
    const getVat = () => {
        let vat = 0;
        let rate = parseFloat(getSystemSettings(generalSettings, "type_vat"));
        vat = (getTotal() * rate) / 100;
        return vat;
    };

    //handle changes
    const handleChange = (e) => {
        e.preventDefault();
        setOrderDetails({
            ...orderDetails,
            [e.target.name]: e.target.value,
        });
    };
    // stripe payment states
    const [stripeDetails, setstripeDetails] = useState({
        stripe_card_name: null,
        stripe_card_number: null,
        stripe_card_cvc: null,
        stripe_card_exp_month: null,
        stripe_card_exp_year: null,
        stripe_zip_code: null,
    });
    //submit order cod
    const handleOrderSubmit = (e) => {
        setOrderDetails({
            ...orderDetails,
            uploading: true,
        });
        e.preventDefault();
        let url = BASE_URL + "/website/order";
        let formData = orderDetails;
        formData.subTotal = getTotal();
        formData.vat = getVat();
        formData.delivery_charge = branchDeliveryCharge;
        return axios
            .post(url, orderDetails, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                if (res.data !== "ended") {
                    setShowCheckout(false);
                    setFoodStockWeb(res.data);
                    setOrderDetails({
                        items: [],
                        branch: null,
                        workPeriod: null,
                        workPeriodStatus: false,
                        address: authUserInfo.details && authUserInfo.details.address,
                        name: authUserInfo.details && authUserInfo.details.name,
                        phn_no: authUserInfo.details && authUserInfo.details.phn_no,
                        note: null,
                        payment_type: "COD",
                        uploading: false,
                    });
                    toast.success(`${_t(t("Your order has been placed"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                } else {
                    toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                    setOrderDetails({
                        ...orderDetails,
                        uploading: false,
                    });
                }
            })
            .catch((err) => {
                setOrderDetails({
                    ...orderDetails,
                    uploading: false,
                });
                toast.error(`${_t(t("Please try again"))}`, {
                    position: "bottom-center",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                });
            });
    };

    // handle stripe on change
    const onchangehandleStripePayment = (e) => {
        setstripeDetails({ ...stripeDetails, [e.target.name]: e.target.value })
    };

    // stripe handle submit
    const onsubmithandleStripePayment = (e) => {
        e.preventDefault();
        document.getElementById('stripePaynow').innerHTML = "Please wait...";
        let url = BASE_URL + "/settings/get-stripe-data";

        let formdata = stripeDetails;
        formdata.subTotal = getTotal();
        formdata.vat = getVat();
        formdata.delivery_charge = branchDeliveryCharge;
        return axios
            .post(url, formdata, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                // make reuseble payment component
                if (res.data.status == 422) {
                    document.getElementById('stripePaynow').innerHTML = "Pay Now";
                    toast.error(`${_t(t("Please enter valid info"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                } else {
                    const payment_id = res.data.card.id;
                    const payment_type = "STRIPE";

                    // send data to serve start
                    setOrderDetails({
                        ...orderDetails,
                        uploading: true,
                    });
                    e.preventDefault();
                    let url = BASE_URL + "/website/order";
                    let formData = orderDetails;
                    formData.subTotal = getTotal();
                    formData.vat = getVat();
                    formData.payment_type = payment_type;
                    formData.payment_id = payment_id;
                    formData.delivery_charge = branchDeliveryCharge;
                    return axios
                        .post(url, orderDetails, {
                            headers: { Authorization: `Bearer ${getCookie()}` },
                        })
                        .then((res) => {
                            if (res.data !== "ended") {
                                setShowCheckout(false);
                                setFoodStockWeb(res.data);
                                setOrderDetails({
                                    items: [],
                                    branch: null,
                                    workPeriod: null,
                                    workPeriodStatus: false,
                                    address: authUserInfo.details && authUserInfo.details.address,
                                    name: authUserInfo.details && authUserInfo.details.name,
                                    phn_no: authUserInfo.details && authUserInfo.details.phn_no,
                                    note: null,
                                    payment_type: "COD",
                                    uploading: false,
                                });
                                toast.success(`${_t(t("Your order has been placed"))}`, {
                                    position: "bottom-center",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    className: "text-center toast-notification",
                                });
                            } else {
                                toast.error(`${_t(t("Sorry, this branch is closed now"))}`, {
                                    position: "bottom-center",
                                    autoClose: 10000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    className: "text-center toast-notification",
                                });
                                setOrderDetails({
                                    ...orderDetails,
                                    uploading: false,
                                });
                            }
                            document.getElementById('stripePaynow').innerHTML = "Pay Now";
                        })
                        .catch((err) => {
                            setOrderDetails({
                                ...orderDetails,
                                uploading: false,
                            });
                            toast.error(`${_t(t("Please try again"))}`, {
                                position: "bottom-center",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                className: "text-center toast-notification",
                            });
                            document.getElementById('stripePaynow').innerHTML = "Pay Now";
                        });
                    // send data to serve end
                }

            })
            .catch((err) => {
                console.log(err);
            });
    };

    //set default language on site load
    const handleOnLoadDefaultLang = () => {
        let localLang = localStorage.i18nextLng;
        if (localLang) {
            if (localLang === undefined || localLang.includes("en-")) {
                navLanguageList &&
                    navLanguageList.map((item) => {
                        if (item.is_default === true) {
                            i18n.changeLanguage(item.code);
                            setDefaultLang(item);
                        }
                        return true;
                    });
            } else {
                const temp =
                    navLanguageList &&
                    navLanguageList.find((item) => {
                        return item.code === localLang;
                    });
                setDefaultLang(temp);
                i18n.changeLanguage(localLang);
            }
        }
    };

    //change language to selected
    const handleDefaultLang = (e) => {
        let lang =
            navLanguageList &&
            navLanguageList.find((theItem) => {
                return theItem.id === parseInt(e.target.value);
            });
        i18n.changeLanguage(lang.code);
        setDefaultLang(lang);
        toast.success(`${_t(t("Language has been switched!"))}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
    };

    //set default currency on site load
    const handleOnLoadDefaultCurrency = () => {
        let localCurrency = JSON.parse(localStorage.getItem("currency"));
        if (localCurrency === null) {
            navCurrencyList &&
                navCurrencyList.map((item) => {
                    if (item.is_default === true) {
                        setDefaultCurrency(item);
                        localStorage.setItem("currency", JSON.stringify(item));
                    }
                    return true;
                });
        } else {
            const temp =
                navCurrencyList &&
                navCurrencyList.find((item) => {
                    return item.code === localCurrency.code;
                });
            setDefaultCurrency(temp);
        }
    };

    //change currency to selected
    const handleDefaultCurrency = (e) => {
        let item =
            navCurrencyList &&
            navCurrencyList.find((theItem) => {
                return theItem.id === parseInt(e.target.value);
            });
        localStorage.setItem("currency", JSON.stringify(item));
        setDefaultCurrency(item);
        toast.success(`${_t(t("Currency has been changed!"))}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
        });
    };

    //get selected branch
    const getSelectedBranch = (id) => {
        if (orderDetails && orderDetails.branch !== null) {
            if (id === orderDetails.branch.id) {
                return true;
            }
        }
        return false;
    };

    //paypal integration
    const initialOptions = {
        // "client-id": { paypal_client_id },
        "client-id": "AWOafqislzl8zx6-w5BwIOu9p-7DXKNt3Ly4hGzXYNRYBKJkY_yrUcAYSc5RP6YFz_ckikuYoDoBs9NK",
        currency: "USD",
        intent: "capture",
    };

    return (
        <>
            <Helmet>
                <title>
                    {generalSettings && getSystemSettings(generalSettings, "siteName")}
                </title>
                <link rel="stylesheet" href="/website/css/animate.css" />
                <link rel="stylesheet" href="/website/css/meanmenu.min.css" />
                <link rel="stylesheet" href="/website/css/bootstrap.min.css" />
                <link rel="stylesheet" href="/website/css/font-awsome-all.min.css" />
                <link rel="stylesheet" href="/website/css/magnific-popup.css" />
                <link rel="stylesheet" href="/website/css/slick.css" />
                <link rel="stylesheet" href="/website/css/jquery-ui.css" />
                <link rel="stylesheet" href="/website/css/style.css" />

                <script src="/website/js/vendor/jquery-2.2.4.min.js"></script>
                <script src="/website/js/vendor/bootstrap.min.js"></script>
                <script src="/website/js/vendor/jquery.meanmenu.min.js"></script>
                <script src="/website/js/vendor/jquery.magnific-popup.min.js"></script>
                <script src="/website/js/vendor/slick.min.js"></script>
                <script src="/website/js/vendor/counterup.min.js"></script>
                <script src="/website/js/vendor/countdown.js"></script>
                <script src="/website/js/vendor/waypoints.min.js"></script>
                <script src="/website/js/vendor/jquery-ui.js"></script>
                <script src="/website/js/vendor/isotope.pkgd.min.js"></script>
                <script src="/website/js/vendor/easing.min.js"></script>
                <script src="/website/js/vendor/wow.min.js"></script>
                <script src="/website/js/simplebar.js"></script>
                <script src="/website/js/main.js"></script>
            </Helmet>

            {/* <!-- Preloader Starts --> */}
            <div className={`preloader02 ${!loading && "d-none"}`} id="preloader02">
                <div className="preloader-inner">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
            </div>

            {/* template content section */}
            <div className={loading ? "d-none" : ""}>
                <Header/>


                {/*snack section*/}
                <div className="snack-section margin-top-50 padding-top-50 padding-bottom-50 margin-bottom-20">
                    {/*<div className="snack-shapes">*/}
                        {/*<span className="mss1"><img src="website/images/img/img/htleaf.png" alt=""/></span>*/}
                        {/*<span className="mss2"><img src="website/images/img/htshape2.png" alt=""/></span>*/}
                        {/*<span className="mss3"><img src="website/images/img/htshape2.png" alt=""/></span>*/}
                        {/*<span className="mss4"><img src="website/images/img/htjuice.png" alt=""/></span>*/}
                    {/*</div>*/}
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <div className="snack-left margin-bottom-60">
                                    <div className="snack-lower-content common-title-area">
                                        <h2>The Best Mediterranean<br/>
                                        <span>Food Healthy And Delicious</span></h2>
                                        <br/>
                                        <p>
                                            In the perfect, most beautiful setting, can exist only the most amazing restaurant. What started out as a personal desire for quality. Mediterranean restaurant food options, has turned into a thriving family-owned restaurant. We take great pride in offering only the freshest organic foods and maintaining five-star quality ratings.
                                        </p>
                                        {/*<div*/}
                                        {/*    className="order-box2 d-flex align-items-center flex-wrap justify-content-center justify-content-sm-start margin-top-35">*/}
                                        {/*    <div><a href="shopping-cart.html" className="btn2">order now</a></div>*/}
                                        {/*    <div className="amount">*/}
                                        {/*        <h5>price : <span>$10.50</span></h5>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="snack-upper-content d-flex align-items-center margin-bottom-35">
                                        <div className="snack-img">
                                            <img src="website/images/img/h30.jpg" alt=""/>
                                        </div>
                                        <br/>
                                        <div className="snack-content">
                                            <h5 className="caveat">RUTH REICH</h5>
                                            <h5>Pull up a chair. Take a taste. Come join us. Life is so endlessly delicious.</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="snack-right">
                                    <div className="snack-inner-shapes">
                                        {/*<span className="ss1"><img src="website/images/img/htcane.png" alt=""/></span>*/}
                                        {/*<span className="ss2"><img src="website/images/img/htleaf.png" alt=""/></span>*/}
                                        {/*<span className="ss3"><img src="website/images/img/htleaf2.png" alt=""/></span>*/}
                                    </div>
                                    <img className='hero-img' src="website/images/img/jmh_5104.png" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="serve-passion padding-top-50 padding-bottom-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 margin-bottom-60 wow fadeInLeft"
                                 style={{ visibility: "visible" ,'animation-name': "fadeInLeft" }}>
                                <div className="serve-left">
                                    <div className="ss-shapes">
                                        <span className="ss1 item-bounce"><img src="website/images/shapes/14.png" alt=""/></span>
                                        <span className="ss2 item-animateTwo"><img src="website/images/shapes/6.png"
                                                                                   alt=""/></span>
                                        <span className="ss3"><img src="website/images/shapes/34.png" alt=""/></span>
                                    </div>
                                    <img className="margin-bottom-20" src="website/images/img/chef.jpg" style={{ height:"300px",width:"290px"}} alt=""/>
                                        <h5>Ebrahim Takkem</h5>
                                        <p>Chef</p>
                                        {/*<div className="inner-counter">*/}
                                        {/*    <div className="about-gallery-4 text-center">*/}
                                        {/*        <img className="img" src="website/images/icons/3.png" alt=""/>*/}
                                        {/*            <div className="items">Delicious</div>*/}
                                        {/*            <p>Food</p>*/}
                                        {/*            <span className="g-s-4"><img src="website/images/shapes/10.png"*/}
                                        {/*                                         alt=""/></span>*/}
                                        {/*            <span className="g-s-5"><img src="website/images/shapes/14.png"*/}
                                        {/*                                         alt=""/></span>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                </div>

                            </div>
                            <div className="col-lg-6 wow fadeInRight"
                                 style={{ visibility: "visible" ,'animation-name': "fadeInRight" }}>
                                <div className="serve-right">
                                    <div className="common-title-area  padding-bottom-60">
                                        <h3>our chef</h3>
                                        <h2>we serve <span>passion</span></h2>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <p>At Green Corner you can be assured to leave with a satisfied belly. Our portions are not only healthy in size, but also healthy in general. We cook with the freshest of ingredients, and you will notice the difference at first bite. We also leave a sweet taste in your mouth at last bite with our complimentary, fresh baked baklava. We genuinely appreciate and value all of our guests.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="about-area about-area2 padding-top-50 padding-bottom-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12  wow fadeInLeft"
                                 style={{ visibility: 'visible', 'animation-name': 'fadeInLeft'}}>
                                <div className="about-left">
                                    <div className="about-l-shapes">
                                        <span className="als-1"><img src="website/images/shapes/2.png" alt=""/></span>
                                    </div>
                                    <div className="row">
                                        <div
                                            className="col-lg-4 col-md-4 col-sm-4 col-4 d-flex align-items-end justify-content-end margin-bottom-20">
                                            <div className="about-gallery-1">
                                                <img src="website/images/img/insidewpeople-circle.jpg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-8 col-sm-8 col-8 margin-bottom-20">
                                            <div className="about-gallery-2">
                                                <img src="website/images/img/img/outside.jpg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                            <div className="about-gallery-3">
                                                <img src="website/images/img/img/inside.jpg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-md-5 col-sm-5 col-5 d-flex align-items-stretch ">
                                            <div className="about-gallery-5 text-center">
                                                <img src="website/images/img/outside-circle.jpg" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12  wow fadeInRight"
                                 style={{ visibility: 'visible', 'animation-name': 'fadeInRight'}}>
                                <div className="about-right">
                                    <div className="about-r-shapes">
                                        <span className="as-1"><img src="website/images/shapes/1.png" alt=""/></span>
                                    </div>
                                    <h2>OUR STORY<br/>
                                        <span>About Our Restaurant</span>
                                    </h2>
                                    <p>Mediterranean food in Mesa And Chandler cooked by Professional Chef who has more than 10 years of experience in the field, cooking in passion, love and health. Green Corner is filled with long, rich traditions of Levantine cooking, a culinary heritage found across Lebanon, Jordan, and Syria, which is the native country of chef-owner Ebrahim Takkem. The menu is also filled with things like kebabs, gyro, and chicken shawarma. You’ll also find specialties like kibbeh — fragrant, crackly bulgur-beef croquettes — and knafeh, the wonderful syrup-soaked cheese pastry that’s baked in enormous sheet pans. All meals end with a complimentary piece of baklava for that sweet end to a delicious meal.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*features section*/}
                <section className="features-section padding-bottom-50 padding-top-50">
                    <div className="common-title-area text-center padding-bottom-50 wow fadeInUp">
                        <h3>OUR SERVICES</h3>
                        <h2>Why We <span>Are Different</span></h2>
                    </div>
                    <div className="container position-relative">
                        <div className="fea-shapes">
                            <span className="fs-1 item-bounce"><img src="website/images/img/htshape7.png" alt=""/></span>
                            <span className="fs-2 item-bounce"><img src="website/images/img/htshape8.png" alt=""/></span>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".2s">
                                <div className="single-fea">
                                    <div className="">
                                        <img src="website/images/img/img/Fresh Vegetables.jpg" style={{width:"250px",height:"200px",paddingBottom: "10px",margin: "auto"}} alt=""/>
                                    </div>
                                    <div className="fea-content text-center">
                                        <h5>Fresh Vegetables</h5>
                                        <p>We chop our vegetables fresh, daily.</p>
                                        {/*<a href="shopping-cart.html">Order Now</a>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".4s">
                                <div className="single-fea">
                                    <div className="">
                                        <img src="website/images/img/img/Healthy Portions.jpg" style={{width:"250px",height:"200px",paddingBottom: "10px",margin: "auto"}} alt=""/>
                                    </div>
                                    <div className="fea-content text-center">
                                        <h5>Healthy Portions</h5>
                                        <p>Our portions will leave any appetite more than satisfied.</p>
                                        {/*<a href="shopping-cart.html">Order Now</a>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".6s">
                                <div className="single-fea">
                                    <div className="">
                                        <img src="website/images/img/img/Baklava.jpg" style={{width:"250px",height:"200px",paddingBottom: "10px",margin: "auto"}} alt=""/>
                                    </div>
                                    <div className="fea-content text-center">
                                        <h5>Complimentary Baklava</h5>
                                        <p>Our gesture of hospitality is leaving you with the sweet taste of our homemade baklava dessert.</p>
                                        {/*<a href="shopping-cart.html">Order Now</a>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".8s">
                                <div className="single-fea">
                                    <div className="">
                                        <img src="website/images/img/img/Marinated Perfection.jpg" style={{width:"250px",height:"200px",paddingBottom: "10px",margin: "auto"}} alt=""/>
                                    </div>
                                    <div className="fea-content text-center">
                                        <h5>Marinated Perfection</h5>
                                        <p>Meats are slow cooked & marinated every day.</p>
                                        {/*<a href="shopping-cart.html">Order Now</a>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".8s">
                                <div className="single-fea">
                                    <div className="">
                                        <img src="website/images/img/img/Not Just Hummus.jpg" style={{width:"250px",height:"200px",paddingBottom: "10px",margin: "auto"}} alt=""/>
                                    </div>
                                    <div className="fea-content text-center">
                                        <h5>Not Just Hummus</h5>
                                        <p>You will see not all hummus is the same.</p>
                                        {/*<a href="shopping-cart.html">Order Now</a>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay=".8s">
                                <div className="single-fea">
                                    <div className="">
                                        <img src="website/images/img/img/Gourmet.jpg" style={{width:"250px",height:"200px",paddingBottom: "10px",margin: "auto"}} alt=""/>
                                    </div>
                                    <div className="fea-content text-center">
                                        <h5>Gourmet</h5>
                                        <p>We serve gourmet Mediterranean food in a fast food type environment.</p>
                                        {/*<a href="shopping-cart.html">Order Now</a>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*softdrink promo*/}
                <section className="softdrink-section padding-bottom-50 padding-top-50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 wow fadeInLeft">
                                <div className="softdrink-left">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 margin-bottom-30">
                                            <div className="drink-review margin-bottom-30">
                                                <div className="review-img">
                                                    <img src="website/images/img/hticon1.png" alt=""/>
                                                </div>
                                                <div className="drink-content">
                                                    <div className="reviews">
                                                        <span><i className="fas fa-star"></i></span>
                                                        <span><i className="fas fa-star"></i></span>
                                                        <span><i className="fas fa-star"></i></span>
                                                        <span><i className="fas fa-star"></i></span>
                                                        <span><i className="fas fa-star"></i></span>
                                                    </div>
                                                    <h5>5 star rating</h5>
                                                    <p>Our All Drink Prodcut Kegs of
                                                        the moment!</p>
                                                </div>
                                            </div>
                                            <div className="s-gallery-1">
                                                <img src="website/images/img/img/Enjoy Our Delicious And Healthy Food2.jpg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="s-gallery-2 margin-bottom-30">
                                                <img src="website/images/img/img/Enjoy Our Delicious And Healthy Food.jpg" alt=""/>
                                            </div>
                                            <div className="s-gallery-3">
                                                <img className="g-shape" src="website/images/img/htshape10.png" alt=""/>
                                                    <h4>25 Years+
                                                    </h4>
                                                    <p>experience skills</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInRight">
                                <div className="softdrink-right">
                                    <h2>Enjoy Our Delicious
                                        <span> And Healthy Food</span>
                                    </h2>
                                    <p>
                                        1 of the most underrated Mediterranean places in PHX. I was recommended this place and I'm so glad I gave this place a try. Now every time I'm in the area I have to stop by.

                                        First thing I'll point out is that their portions are HUGE. they are overly generous w how much they give you...and that's one reason why I love this place. (I can get two separate meals out of it)

                                        Their quality of food is authentic and fresh!

                                        I always get the mix shawarma plate a combo of everything and sub out the salad for couscous

                                        From time to time they even come by & give you each a piece of delicious baklava (which I really hope they don't stop doing) ;)

                                        Highly recommend this place for some good Mediterranean food!!

                                        1 of the most underrated Mediterranean places in PHX. I was recommended this place and I'm so glad I gave this place a try. Now every time I'm in the area I have to stop by.

                                        First thing I'll point out is that their portions are HUGE. they are overly generous w how much they give you...and that's one reason why I love this place. (I can get two separate meals out of it)

                                        Their quality of food is authentic and fresh!

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- drink-items section --> */}
                <section className="blog-area padding-top-50 padding-bottom-50">
                    <div className="blog-shapes">
                        <span className="bs-1"><img src="/website/images/img/30.png" alt="" /></span>
                    </div>
                    <div className="container">
                        <div className="common-title-area text-center padding-bottom-60 wow fadeInUp">
                            <h2>
                                {_t(t("Explore Our"))} <span>{_t(t("Special Menu"))}</span>
                            </h2>
                        </div>
                        <div className="row">
                            {foodListWeb &&
                            foodListWeb.map((special, index) => {
                                if (parseInt(special.is_special) === 1) {
                                    return (

                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div className="single-blog px-4 template-single-product">
                                                <div className="blog-img">
                                                    <a
                                                        href="#!"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setFoodItems({
                                                                ...foodItems,
                                                                selectedItem: special,
                                                                variations:
                                                                    parseInt(special.has_variation) === 1
                                                                        ? special.variations
                                                                        : null,
                                                                properties:
                                                                    parseInt(special.has_property) === 1
                                                                        ? special.properties
                                                                        : null,
                                                            });
                                                            setNewOrder({
                                                                variation: null,
                                                                quantity: 1,
                                                                properties: null,
                                                            });
                                                            setShowVariation(true);
                                                        }}
                                                    >
                                                        <img src={special.image} alt="" />
                                                    </a>
                                                </div>
                                                <div className="d-flex justify-content-center mt-4">
                                                    {parseInt(special.has_variation) === 0 && (
                                                        <h6 className="price mr-4">
                                                            {_t(t("price"))} :{" "}
                                                            {currencySymbolLeft() +
                                                            formatPrice(special.price) +
                                                            currencySymbolRight()}
                                                        </h6>
                                                    )}

                                                    <span className="badge bg-danger text-white">special</span>
                                                </div>

                                                <h5 className="mt-4 text-center"><a
                                                    className="text-dark"
                                                    href="#!"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setFoodItems({
                                                            ...foodItems,
                                                            selectedItem: special,
                                                            variations:
                                                                parseInt(special.has_variation) === 1
                                                                    ? special.variations
                                                                    : null,
                                                            properties:
                                                                parseInt(special.has_property) === 1
                                                                    ? special.properties
                                                                    : null,
                                                        });
                                                        setNewOrder({
                                                            variation: null,
                                                            quantity: 1,
                                                            properties: null,
                                                        });
                                                        setShowVariation(true);
                                                    }}
                                                > {special.name}</a></h5>
                                                <p>{special.desc}</p>
                                            </div>

                                        </div>

                                    );
                                } else {
                                    return false;
                                }
                            })}
                        </div>
                        <br/>
                        <div className="menu-btn text-center">
                            <a href="/menu" className="btn2">Explore All Items</a>
                        </div>
                    </div>
                </section>

                {/*drink-ind section*/}
                <section className="drink-ind padding-top-50 padding-bottom-50">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 wow fadeInLeft">
                                <div className="drink-ind-left margin-bottom-30">
                                    <img className="drs1 item-bounce" src="website/images/img/htshape20.png" alt=""/>
                                        <img className="drs2 item-bounce" src="website/images/img/htshape21.png" alt=""/>
                                            {/*<img className="drs3" src="website/images/img/htshape22.png" alt=""/>*/}
                                            {/*    <img className="drs4 item-animateTwo" src="website/images/img/htshape2.png" alt=""/>*/}
                                                    <img className="drs5" src="website/images/img/htleaf.png" alt=""/>
                                                         {/*main img*/}
                                                        <img src="website/images/img/img/best-ingredients-food-on-right.jpg" alt=""/>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeInRight">
                                <div className="drink-ind-right">
                                    <div className="common-title-area3 text-center text-md-left">
                                        <h2>Best Ingredients
                                            Food<span> On Right
                                    Handmade</span> </h2>
                                    </div>
                                    <p>
                                        1 of the most underrated Mediterranean places in PHX. I was recommended this place and I'm so glad I gave this place a try. Now every time I'm in the area I have to stop by.

                                        First thing I'll point out is that their portions are HUGE. they are overly generous w how much they give you...and that's one reason why I love this place. (I can get two separate meals out of it)

                                        Their quality of food is authentic and fresh!

                                        I always get the mix shawarma plate a combo of everything and sub out the salad for couscous

                                        From time to time they even come by & give you each a piece of delicious baklava (which I really hope they don't stop doing) ;)

                                        Highly recommend this place for some good Mediterranean food!!

                                        1 of the most underrated Mediterranean places in PHX. I was recommended this place and I'm so glad I gave this place a try. Now every time I'm in the area I have to stop by.

                                        First thing I'll point out is that their portions are HUGE. they are overly generous w how much they give you...and that's one reason why I love this place. (I can get two separate meals out of it)

                                        Their quality of food is authentic and fresh!

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*food-brand section*/}
                <section className="food-brand-section padding-top-50">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 margin-bottom-60 wow fadeInLeft">
                                <div className="food-brand-left text-center text-md-left">
                                    <div className="common-title-area3">
                                        <h2 className="margin-bottom-20">Meats Are Slow Cooked  <span>& Marinated Every Day</span> </h2>
                                        <p> 1 of the most underrated Mediterranean places in PHX. I was recommended this place and I'm so glad I gave this place a try. Now every time I'm in the area I have to stop by.

                                            First thing I'll point out is that their portions are HUGE. they are overly generous w how much they give you...and that's one reason why I love this place. (I can get two separate meals out of it)

                                            Their quality of food is authentic and fresh!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 d-none d-sm-block offset-lg-1 wow fadeInRight">
                                <div className="food-brand-right margin-bottom-30">
                                    {/*<img className="fb1 item-bounce" src="website/images/img/htleaf2.png" alt=""/>*/}
                                        <img className="fb2 item-bounce" src="website/images/img/htshape25.png" alt=""/>
                                            <img className="fb3 item-bounce" src="website/images/img/htshape20.png" alt=""/>
                                                <img className="fb4" src="website/images/img/htshape26.png" alt=""/>
                                                    <img className="fb5 item-animateTwo" src="website/images/img/htshape2.png" alt=""/>
                                                        <img className="fb6" src="website/images/img/htleaf.png" alt=""/>
                                                            <img className="fb7" src="website/images/img/htshape2.png" alt=""/>
                                                                {/*main img*/}
                                                                <img src="website/images/img/img/Meats-Are-Slow-Cooked-&-Marinated-Every-Day.jpg" alt="" style={{width:"500px"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*ht testimonial*/}
                <Testimonial/>

                <Blog/>
                
                <Footer/>
            </div>
            {/* template content end here */}
        </>
    );
};

export default TemplateThree;
